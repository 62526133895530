import React, { useContext, useEffect, useState } from 'react';
import { Button, Row, Col, Spinner } from 'react-bootstrap'
import User from '../contexts/User';
import ServerStatus from './ServerStatus';

function Illumitype(props) {
    const user = useContext(User.Context)
    const [downloadLink, setDownloadLink] = useState({ pending: false, link: '', error: false })
    const [showLicenseKey, setShowLicenseKey] = useState(false)

    useEffect(() => {
        if (!user.data.licenseKey) {
            (async () => {
                try {
                    const response = await fetch(process.env.REACT_APP_ACCOUNT_URL + '/licensekey', {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            Authorization: `Bearer ${user.data.token}`
                        }
                    })

                    const json = await response.json()
                    user.set({ ...user.data, licenseKey: json.licenseKey })
                } catch (err) {
                    console.log(err)
                }
            })()
        }
    }, [user])

    return (
        <>
            <Row className="justify-content-center"><h2>Välkommen till distansportalen!</h2></Row>
            <Row className="justify-content-center align-items-center mb-2">
                <Col sm="auto">
                    <h4>Licensnyckel</h4>
                </Col>
                <Col className="d-flex" sm="auto">
                    {showLicenseKey ? <p>{user.data.licenseKey}</p> : <Button onClick={() => setShowLicenseKey(true)}>Visa</Button>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col sm="auto">
                    <Button onClick={async () => {
                        try {
                            setDownloadLink({ pending: true, link: '', error: false })
                            const response = await fetch(process.env.REACT_APP_UPDATE_URL + '/download/latest', {
                                method: 'GET',
                                credentials: 'include',
                                headers: {
                                    'Authorization': `Bearer ${user.data.token}`
                                }
                            })

                            const json = await response.json()

                            setDownloadLink({ pending: false, link: process.env.REACT_APP_UPDATE_URL + '/download/latest/' + json.token, error: false })
                        } catch (err) {
                            setDownloadLink({ pending: false, link: '', error: true })
                        }
                    }}>Ta fram en nedladdningslänk</Button>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col sm="auto">
                    {downloadLink.pending && <Spinner animation="border" />}
                    {downloadLink.error && <p>Nånting gick fel. Du kan prova igen.</p>}
                    {
                        downloadLink.link &&
                        <Button className="mt-2" href={downloadLink.link} onClick={() => setDownloadLink({ pending: false, link: '', error: false })}>Klicka här för att ladda hem</Button>
                    }
                </Col>
            </Row>
            {user.data.roles.includes('activity') ? <ServerStatus /> : null}
        </>
    )
}

export default Illumitype