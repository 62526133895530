import React, { useMemo } from 'react';
import { Accordion, Col, ListGroup, Row } from 'react-bootstrap'
import ActivityData, { ActivityLog, Activity } from '../contexts/Activity'

interface ActivityDetails {
    name: string
    actions: Array<Activity>
    currentlyActive: boolean
    totalTime: number
    detailed: Array<Activity>
}

function getTimeString(time: number): string {
    time /= 1000 * 60
    time = Math.round(time)
    let hours = 0
    while (time >= 60) {
        ++hours
        time -= 60
    }

    let totalTime = hours > 0 ? hours.toString() + 'h ' : ''
    totalTime += time.toString() + 'min'
    return totalTime
}

function Session(props: ActivityDetails) {
    return (
        <ListGroup.Item>
            <Row>
                <Col>{props.name}</Col>
                <Col>Status: {props.currentlyActive ? "Active" : "Passive"}</Col>
                <Col>Tid använd: {getTimeString(props.totalTime)}</Col>
            </Row>
            <Accordion>
                <Accordion.Toggle eventKey="1">Visa detaljer</Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <>
                        {props.actions.map((action, idx) => <div key={idx}>{action.action} {action.createdAt.toLocaleString()}</div>)}
                    </>
                </Accordion.Collapse>
            </Accordion>
        </ListGroup.Item>

    )
}

function Organization(props: { activity: Array<ActivityLog>, organization: string }) {
    const activityDetails = useMemo(() => {
        return props.activity.map(session => {
            let currentlyActive = false
            let actions = session.activity.filter(action => action.action === "sessionActive" || action.action === "sessionPassive")
                .filter((action, idx, arr) => {
                    if (action.action === "sessionActive") {
                        return true
                    }

                    if (arr[idx + 1]?.action === "sessionActive") {
                        const passive = action.createdAt.getTime()
                        const active = arr[idx + 1].createdAt.getTime()

                        const minutes = Math.round((active - passive) / (1000 * 60))
                        if (minutes < 15) {
                            return false
                        }
                    }

                    return true
                })
                .filter((action, idx, arr) => {
                    if (action.action === "sessionActive") {
                        if (arr[idx - 1]?.action === "sessionActive") {
                            return false
                        }
                    }
                    return true
                })
                .filter((action, idx, arr) => {
                    if (action.action === "sessionActive") {
                        const active = action.createdAt.getTime()
                        const passive = arr[idx + 1]?.createdAt.getTime()

                        if (passive) {
                            const minutes = Math.round((passive - active) / (1000 * 60))
                            if (minutes < 10) {
                                return false
                            }
                        } else {
                            currentlyActive = true
                        }

                        return true
                    } else if (action.action === "sessionPassive") {
                        const passive = action.createdAt.getTime()
                        const active = arr[idx - 1]?.createdAt.getTime()

                        if (passive) {
                            const minutes = Math.round((passive - active) / (1000 * 60))
                            if (minutes < 10) {
                                return false
                            }
                        }

                        return true
                    }
                    return true
                })

            let totalTime = 0
            actions.forEach((action, idx, arr) => {
                if (action.action === "sessionPassive") {
                    if (arr[idx - 1]?.action === "sessionActive") {
                        totalTime += action.createdAt.getTime() - arr[idx - 1].createdAt.getTime()
                    }
                }
            })

            return { name: session.settings[session.settings.length - 1].name, actions, currentlyActive, totalTime, detailed: session.activity } as ActivityDetails
        })
    }, [props.activity])

    const totalTime = activityDetails.reduce<number>((prev, cur) => { return cur.totalTime + prev }, 0)

    return (
        <>
            <Accordion>
                <Accordion.Toggle eventKey={props.organization}>
                    {props.organization}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={props.organization}>
                    <>
                        <Row>
                            <Col>
                                Antal sessioner: {props.activity.length}
                            </Col>
                            <Col>
                                Total tid: {getTimeString(totalTime)}
                            </Col>
                        </Row>
                        <ListGroup>
                            {activityDetails.map(session => <Session {...session} />)}
                        </ListGroup>
                    </>
                </Accordion.Collapse>
            </Accordion>
        </>
    )
}

function ActivityOverview(props: any) {
    const [activity] = ActivityData.useCachedData()

    const organizations: Record<string, Array<ActivityLog>> = {}

    for (const log of activity) {
        if (!organizations[log.BelongsTo]) {
            organizations[log.BelongsTo] = []
        }
        organizations[log.BelongsTo].push(log)
    }

    return (
        <>
            {Object.entries(organizations).map(([key, value], idx) => {
                return <Organization key={idx} activity={value} organization={key} />
            })}
        </>
    )
}

export default ActivityOverview