import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap'

function ConfirmDialogButton(props) {
    const [show, setShow] = useState(false)
    return (
        <>
            <Button variant={props.variant} size={props.size} onClick={() => setShow(true)}>{props.label}</Button>
            <Modal size="sm" centered show={show} onHide={() => setShow(false)}>
                <Modal.Body>
                    <Row>
                        <Col className="text-center">
                            {props.text}
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm="auto">
                            <Button onClick={() => {
                                props.onYes()
                                setShow(false)
                            }}>Ja</Button>
                        </Col>
                        <Col sm="auto">
                            <Button onClick={() => setShow(false)}>Nej</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmDialogButton