import React, { useContext } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap'
import User from '../contexts/User';
import { GoogleLogout } from 'react-google-login';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import Licenses from '../views/Licenses';
import Versions from '../views/Versions';
import Illumitype from '../views/Illumitype';
import Lists from '../views/Lists';
import Sessions from '../views/Sessions';
import GroupsAndRoles from '../views/GroupsAndRoles';
import ErrorBounday from './ErrorBoundary';
import Help from '../views/Help';
import Transcript from '../views/transcripts/Transcript';
import Activity from '../views/Activity';
import LogoWithText from './SSAB-Logo.png';


const pages = [
    { to: '/versions', label: 'Versioner', Component: <Versions />, role: 'updates' },
    { to: '/licenser', label: 'Licensnycklar', Component: <Licenses />, role: 'accounts' },
    { to: '/lists', label: 'Listor', Component: <Lists /> },
    { to: '/sessions', label: 'Sessioner', Component: <Sessions />, role: 'sessions' },
    { to: '/sessions', label: 'Sessioner', Component: <Sessions />, role: 'mysessions' },
    { to: '/transcripts', label: "Transkriberingar", Component: <Transcript /> },
    { to: '/activity', label: "Aktivitetslogg", Component: <Activity />, role: 'activity' },
    { to: '/groups', label: 'Grupper & Roller', Component: <GroupsAndRoles />, role: 'accounts' },
    { to: '/help', label: 'Hjälp', Component: <Help /> },
]

function Navigation(props) {
    const user = useContext(User.Context)

    const usedPages = pages.filter(page => !page.role || user.data.roles.includes(page.role))

    return (
        <Router>
            <Navbar bg="light" expand="md">
                <Link as={Link} to="/"><img alt="" src={LogoWithText} width="120" height="40" /></Link>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav className="mr-auto">
                        {usedPages.map(page => <Nav.Link key={page.to} as={Link} to={page.to}>{page.label}</Nav.Link>)}
                    </Nav>
                    <Navbar.Text className="mr-1">
                        {`${user?.data.first_name} ${user?.data.last_name}`}
                    </Navbar.Text>
                    <GoogleLogout
                        clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                        onLogoutSuccess={() => user.reset()}
                        render={p => <Button onClick={() => {
                            if (user.data.loggedInWithGoogle) {
                                p.onClick()
                            } else {
                                user.reset()
                            }
                        }}>Logga ut</Button>}
                    />
                </Navbar.Collapse>
            </Navbar>
            <ErrorBounday>
                <Switch>
                    {usedPages.map(page => {
                        return (
                            <Route key={page.to} path={page.to}>
                                {page.Component}
                            </Route>
                        )
                    })}
                    <Route path="/">
                        <Illumitype />
                    </Route>
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </ErrorBounday>
        </Router>
    )
}

export default Navigation