import React from 'react';
import { NavContent } from '../components/ContentNavigation';

import anvandaforkortningar1 from '../help-images/anvandaforkortningar1.png'
import anvandaforkortningar2 from '../help-images/anvandaforkortningar2.png'

import forkortningsstatistik1 from '../help-images/forkortningsstatistik1.png'

import distanstolkning1 from '../help-images/distanstolkning1.png'
import distanstolkning2 from '../help-images/distanstolkning2.png'
import distanstolkning3 from '../help-images/distanstolkning3.png'
import distanstolkning4 from '../help-images/distanstolkning4.png'
import distanstolkning5 from '../help-images/distanstolkning5.png'
import distanstolkning6 from '../help-images/distanstolkning6.png'
import distanstolkning7 from '../help-images/distanstolkning7.png'
import distanstolkning8 from '../help-images/distanstolkning8.png'
import distanstolkning9 from '../help-images/distanstolkning9.png'
import distanstolkning10 from '../help-images/distanstolkning10.png'
import distanstolkning11 from '../help-images/distanstolkning11.png'
import distanstolkning12 from '../help-images/distanstolkning12.png'
import distanstolkning13 from '../help-images/distanstolkning13.png'
import distanstolkning14 from '../help-images/distanstolkning14.png'

import fjarrtolkning1 from '../help-images/fjarrtolkning1.png'
import fjarrtolkning2 from '../help-images/fjarrtolkning2.png'
import fjarrtolkning3 from '../help-images/fjarrtolkning3.png'
import fjarrtolkning4 from '../help-images/fjarrtolkning4.png'
import fjarrtolkning5 from '../help-images/fjarrtolkning5.png'
import fjarrtolkning6 from '../help-images/fjarrtolkning6.png'

import taover1 from '../help-images/taover1.png'

import chatta1 from '../help-images/chatta1.png'
import chatta2 from '../help-images/chatta2.png'

import importexport1 from '../help-images/importexport1.png'
import importexport2 from '../help-images/importexport2.png'
import importexport3 from '../help-images/importexport3.png'
import importexport4 from '../help-images/importexport4.png'

import listor1 from '../help-images/listor1.png'
import listor2 from '../help-images/listor2.png'
import listor3 from '../help-images/listor3.png'
import listor4 from '../help-images/listor4.png'
import listor5 from '../help-images/listor5.png'
import listor6 from '../help-images/listor6.png'
import listor7 from '../help-images/listor7.png'
import listor8 from '../help-images/listor8.png'

import skapaforkortning1 from '../help-images/skapaforkortning1.png'
import skapaforkortning2 from '../help-images/skapaforkortning2.png'
import skapaforkortning3 from '../help-images/skapaforkortning3.png'
import skapaforkortning4 from '../help-images/skapaforkortning4.png'
import skapaforkortning5 from '../help-images/skapaforkortning5.png'
import skapaforkortning6 from '../help-images/skapaforkortning6.png'
import skapaforkortning7 from '../help-images/skapaforkortning7.png'

import uppdatering1 from '../help-images/uppdatering1.png'
import uppdatering2 from '../help-images/uppdatering2.png'
import { Image as ReactImage, Row } from 'react-bootstrap';

function Yellow(props) {
    return <span style={{ backgroundColor: "yellow" }}>{props.children}</span>
}

function Green(props) {
    return <span style={{ backgroundColor: "green" }}>{props.children}</span>
}

function Blue(props) {
    return <span style={{ backgroundColor: "blue", color: "white" }}>{props.children}</span>
}

function Orange(props) {
    return <span style={{ backgroundColor: "orange" }}>{props.children}</span>
}

function Pink(props) {
    return <span style={{ backgroundColor: "pink" }}>{props.children}</span>
}

function Image(props) {
    return <Row className="justify-content-center">
        <ReactImage {...props} />
    </Row>
}

const content = [
    {
        id: "Introduktion",
        header: "Introduktion",
        content: <div>
            <p>
                IllumiType är ett textredigeringsverktyg skapat av skrivtolkar för skrivtolkar. Med sina många funktioner ger IllumiType möjlighet att enkelt leverera högkvalitativ textning till en rad olika sammanhang. Programmet är i huvudsak byggt för att maximera skrivhastighet och för att underlätta samarbetet mellan textare i olika situationer.
            </p>
            <p>
                Det finns två huvudsakliga visningslägen: textläge och inställningsläge. Du kan växla mellan dem genom att trycka ner <b>F12</b> på ditt tangentbord.
            </p>
            <p>
                Det finns olika kortkommandon för att navigera runt i programmet. Mer om dessa under rubriken snabbknappar.
            </p>
        </div>
    },
    {
        id: "Formatering",
        header: "Formatering",
        content: <div>
            <p>
                IllumiType ser till att reducera allt onödigt manuellt arbete för användaren genom en viss automatisk formatering i textläget:
                <ul>
                    <li>Första bokstaven i varje paragraf är alltid stor.</li>
                    <li>Första bokstaven som följer efter skiljetecken (. ! ? :) blir automatiskt stor.</li>
                </ul>
            </p>
            <p>
                När du vill rensa all text på skärmen tryck på <b>F4</b>. Du kan också öka och minska textstorleken genom att trycka på <b>F6</b> för att öka och <b>F7</b> för att minska. Om du håller nere knappen går det snabbare.
            </p>
            <p>
                I inställningsläget <b>F12</b> kan du under fliken “Utseende” även ställa in font, textstorlek och kantavstånd.
            </p>
            <p>
                Det finns olika verktyg för att anpassa utseendet efter olika användare och situationer.
            </p>
            <p>
                Du kan skapa olika färgscheman i inställningsläget. Ett färgschema innehåller en bakgrundsfärg och en textfärg. Du kan förinställa flera färgscheman och växla mellan färgscheman i textläget genom att trycka på <b>F8</b>.
            </p>
            <p>
                Det finns också sätt att belysa olika delar av texten genom att använda texteffekter. Du kan aktivera en effekt i taget för att applicera den effekten för de kommande tecknen. Det finns <b>fetstil CTRL+B/F</b>, <i>kursiverat</i> <b>CTRL+I/K</b> och <u>understruket</u> <b>CTRL+U</b>. Du kan kombinera alla effekter som du vill.
            </p>
        </div>
    },
    {
        id: "Förkortningar",
        header: "Förkortningar",
        subHeaders: [
            {
                id: "Listor",
                header: "Listor",
                content: <div>
                    <p>
                        Tryck på <b>F12</b> för att komma till inställningsläget. I menyn väljer du <Yellow>
                            Hantera förkortningar
                        </Yellow>.
                    </p>
                    <Image fluid src={listor1} />
                    <p>
                        Då kommer du få upp de förkortningslistor som finns.  Det kan se ut såhär. För att skapa förkortningar behöver du en aktiv förkortningslista. Vid installation finns redan en tom förkortningslista aktiverad, en så kallad standardlista. Programmet tillåter endast en (1) standardlista att vara aktiverad samtidigt. Du kan se vad det är för sorts lista under
                        <Yellow> Typ</Yellow>.
                    </p>
                    <Image fluid src={listor2} />
                    <p>
                        Det finns även tilläggslistor. Dessa kan aktiveras ovanpå standardlistan. Förkortningarna i en tilläggslista kommer att prioriteras högre än de i standardlistan. Detta innebär att förkortningar i tilläggslistor automatiskt trumfar förkortningen i standardlistan om samma förkortning finns i båda listorna.
                    </p>
                    <p>
                        Om du till exempel har förkortningen mkt för mycket i din standardlista men har förkortningen mkt för makt i din aktiva tilläggslista så kommer förkortningen makt att utlösas.
                    </p>
                    <p>
                        Du aktiverar listor genom att klicka i symbolen till höger om kolumnen <Yellow>Typ</Yellow> så att den blir grönmarkerad.
                    </p>
                    <Image fluid src={listor3} />
                    <p>
                        Med hjälp av pennsymbolen kan du byta namn och typ på din lista. Genom att klicka på papperskorgssymbolen kan du radera en lista.
                    </p>
                    <Image fluid src={listor4} />
                    <p>
                        Under menyn till vänster finns “Prioriteringsordning”. Där har du 10 profiler att välja mellan. Vid installation börjar du på profil 1. Du kan byta namn på dina profiler genom att trycka på pennan. Har du en standardlista och två tilläggslistor aktiverade kan det se ut såhär.
                    </p>
                    <Image fluid src={listor5} />
                    <p>
                        Du kan flytta dina tilläggslistor upp och ner i prioriteringsordningen för att välja vilken listas förkortningar som ska prioriteras högst. Standardlistan kommer alltid prioriteras sist och går inte att flytta på.
                    </p>
                    <Image fluid src={listor6} />
                    <p>
                        För att byta profil klickar du på namnet på din profil, då får du upp en lista med de andra profilerna. Klicka på den du vill aktivera.
                    </p>
                    <Image fluid src={listor7} />
                    <p>
                        På så sätt kan du ha olika profiler för olika kombinationer av standard- och tilläggslistor. Du kan på samma sätt dra i listorna för att ändra prioriteringsordning.
                    </p>
                    <Image fluid src={listor8} />
                    <p>
                        Ute i skrivläget kan du snabbt och lätt byta mellan profiler genom att trycka CTRL+ den siffra din önskade profil har. Så för att växla till profil 2 trycker du <b>CTRL+2</b>. På samma sätt trycker du <b>CTRL+1</b> för att komma tillbaka till profil 1.
                    </p>
                </div>
            },
            {
                id: "ImportExport",
                header: "Importera och exportera listor",
                content: <div>
                    <p>
                        Du kan importera och exportera listor. För att exportera en lista markerar du den lista du vill exportera och trycker sedan på <Yellow>
                            Exportera
                        </Yellow>. Där kan du välja att exportera i IllumiType- eller en SVT-format. SVT-format innebär att förkortningarna står enligt formatet “förkortning=ord”. Exempel: kmr=kommer.
                    </p>
                    <Image fluid src={importexport1} />
                    <p>
                        För att importera ser du till att inte ha någon lista markerad och trycker på knappen “Importera” till vänster om knappen för att göra en ny lista. Du får upp ett fönster och trycker på <Yellow>
                            Välj en fil
                        </Yellow>, då öppnas Utforskaren och du kan välja den filen som innehåller din lista, tryck öppna.
                    </p>
                    <Image fluid src={importexport2} />
                    <Image fluid src={importexport3} />
                    <p>
                        Då kommer du tillbaks till första fönstret, där ser du till att din lista är markerad och trycker sedan på <Yellow>
                            Importera
                        </Yellow>. Du kan importera listor i IllumiType-format, SVT-format, Velonote-format och ToT-format. SVT-format innebär att förkortningarna står enligt formatet “förkortning=ord”. Exempel: kmr=kommer. För att importera listor från Protype behöver du gå via Velonote eller ToT så att listan blir i det filformatet. Sedan går det bra att importera listan i Illumitype.
                    </p>
                    <Image fluid src={importexport4} />
                </div>
            },
            {
                id: "SkapaForkortning",
                header: "Skapa förkortningar",
                content: <div>
                    <p>
                        Du kan skapa förkortningar på två olika sätt. Du kan göra det i inställningsmenyn genom att välja den önskade listan.
                    </p>
                    <Image fluid src={skapaforkortning1} />
                    <p>
                        Då får du en meny till höger om alla listor. Där kan du klicka på <Yellow>
                            NY
                        </Yellow>.
                    </p>
                    <Image fluid src={skapaforkortning2} />
                    <p>
                        Du får då upp detta fönster där du skriver i din önskade förkortning och ordet/orden du vill lägga in.
                    </p>
                    <Image fluid src={skapaforkortning3} />
                    <p>
                        När du klickar på “skapa” finns din förkortning i listan. För att lättare hitta förkortningar i din lista kan du välja att sortera i bokstavsordning på <Green>
                            förkortningen
                        </Green> eller <Blue>
                            ordet
                        </Blue>, <Yellow>
                            datum
                        </Yellow> det har lagts in eller så kan du använda <Orange>
                            sökfunktionen
                        </Orange> ovanför.
                    </p>
                    <Image fluid src={skapaforkortning4} />
                    <p>
                        Ett annat sätt att lägga in förkortningar är i textläget. För att lägga in en förkortning i din standardlista trycker du på <b>F9</b> och får då upp en ruta i övre högra hörnet där din markör hamnar. Där skriver du in din förkortning överst och trycker sedan <b>TAB</b> för att skriva in ordet/orden under. När du sedan trycker <b>ENTER</b> finns din förkortning i din standardlista. Om du redan har skrivit i textläget kommer det senast skrivna ordet upp i raden för “ord” i rutan eller så kan du markera det ord du vill lägga in och trycka <b>F9</b> eller <b>F10</b> för att lägga in det.
                    </p>
                    <Image fluid src={skapaforkortning5} />
                    <p>
                        För att lägga in en förkortning i din först valda tilläggslista trycker du på <b>F10</b>. Du får upp en likadan ruta uppe i högra hörnet, men nu är din tilläggslista vald.
                    </p>
                    <Image fluid src={skapaforkortning6} />
                    <p>
                        Vill du lägga in förkortningen i en annan aktiv tilläggslista trycker du <b>CTRL+TAB</b> för att växla vilken lista du vill lägga in förkortningen i.
                    </p>
                    <p>
                        Skulle du försöka lägga in en förkortning som redan finns så står det i rutan att den förkortningen är upptagen. Du kan välja att lägga in den nya förkortningen ändå men då kommer den gamla förkortningen att försvinna.
                    </p>
                    <Image fluid src={skapaforkortning7} />
                </div>
            },
            {
                id: "AnvandaForkortningar",
                header: "Använda förkortningar",
                content: <div>
                    <p>
                        När du har lagt in de förkortningar du vill ha och har aktiverat din förkortningslista kommer de finnas tillgängliga i skrivläget. Skriver du <b>kmr</b> och trycker <b>SPACE</b> så utlöses det som <i>kommer</i>.
                    </p>
                    <p>
                        Om du inte vill utlösa en förkortning så trycker du <b>TAB</b> istället för <b>SPACE</b>. Skriver du då <b>kmr</b> och trycker <b>TAB</b> så görs ett mellanslag men det står fortfarande <i>kmr</i> istället för <i>kommer</i>.
                    </p>
                    <p>
                        Om du inte vill utlösa en förkortning och sätta skiljetecken direkt efter bokstavskombinationen använder du istället <b>CTRL+TAB</b>. Skriver du då <b>kmr</b> och trycker CTRL+TAB görs inget mellanslag, och det står fortfarande <i>kmr</i> istället för <i>kommer</i>.
                    </p>
                    <p>
                        Du kan även sammanbinda förkortningar genom att trycka <b>CTRL+SPACE</b>. Då kommer förkortningen lösas ut men det blir inget mellanslag och du kan skriva en förkortning direkt efter och när du sedan trycker <b>SPACE</b> efter det så utlöses även den andra förkortningen.
                    </p>
                    <p>
                        Vill du till exempel skriva <i>svensklärare</i> och har <i>svensk</i> på <b>svk</b> och <i>lärare</i> på <b>lre</b> kan du skriva <b>svk</b>, trycka <b>CTRL+SPACE</b>, skriva <b>lre</b> och trycka <b>SPACE</b> så det kommer utlösas som <i>svensklärare</i>.
                    </p>
                    <p>
                        Har du glömt förkortningen på ett ord kan du markera det ordet och trycka <b>F1</b> för att få upp en ruta med förkortningen för det ordet. Du kan även trycka <b>F1</b> direkt efter ordet om det är det sista du har skrivit och du inte har skrivit några skiljetecken eller ord efter det. Kommer det inte upp en ruta så finns ingen förkortning för det ordet.
                    </p>
                    <Image fluid src={anvandaforkortningar1} />
                    <Image fluid src={anvandaforkortningar2} />
                    <p>
                        Om du vill att en förkortning ska skrivas med endast versaler kan du skriva förkortningen med versaler. Alltså löser KMR ut KOMMER. Förkortningar som består av endast en bokstav, eller en bokstav och en siffra löses inte ut som enbart versaler om bokstaven är versal.
                    </p>
                </div>
            }
        ]
    },
    {
        id: "Forkortningsstatistik",
        header: "Förkortningsstatistik",
        content: <div>
            <p>
                I inställningsläge under rubriken Förkortningsstatistik kan du följa statistik över din användning av förkortningar. Här kan du bland annat se följande:
            </p>
            <ul>
                <li>
                    Vilka befintiliga förkortningar du använt flest gånger.
                </li>
                <li>
                    Vilka befintliga förkortningar du missat att använda flest gånger.
                </li>
            </ul>
            <p>
                Funktionen för endast statistik över förkortningar när du är ansluten till en distanssession.  Statistiken kan illustreras enligt följande:
            </p>
            <ul>
                <li>
                    Alla nuvarande data (från en viss session)
                </li>
                <li>
                    All data (sammantaget från alla sessioner)
                </li>
            </ul>
            <p>
                Förkortningsstatistiken kan till exempel sorteras på olika sätt, till exempel efter datum och klockslag då de användes.
            </p>
            <Image fluid src={forkortningsstatistik1} />
        </div>
    },
    {
        id: "NyaDistansportalen",
        header: "Nya distansportalen",
        content: <div>
            <p>
                När det skapas ett IllumiType konto åt dig får du ett mail med ett länk där du kan aktivera kontot och välja ett lösenord. Efter att du valt lösenord går det bra att logga in med din e-post och ditt nya lösenord.
            </p>
            <p>
                Om din e-post är en googleadress så går det också bra att logga in via ditt google-konto. Skulle du glömma bort ditt lösenord finns det en länk för att återställa det på sidan där du loggar in.
            </p>
            <p>
                När du loggat in får du tillgång till dessa sidor:
            </p>
        </div>,
        subHeaders: [
            {
                id: "IllumiType",
                header: "IllumiType",
                content: <div>
                    <p>
                        Här kan du se din licensnyckel och ladda hem installationsfilen för den senaste versionen av IllumiType. Programmet har automatiska uppdateringar. När det släpps en ny version kommer programmet informera dig så du kan uppdatera till den senaste versionen.
                    </p>
                </div>
            },
            {
                id: "DistansportalenListor",
                header: "Listor",
                content: <div>
                    <p>
                        Här kan du ladda upp listor som du exporterat från IllumiType. Listor du laddar upp är tillgängliga för alla tolkar som tillhör din organisation. Du kan slå upp ord och söka i listan. Bara utvalda personer i din organisation har möjlighet att ta bort listor.
                    </p>
                </div>
            },
            {
                id: "hjalp",
                header: "Hjälp",
                content: <div>
                    <p>
                        Information om hur du använder programmet och distansportalen. Uppdateras löpande när ändringar görs.
                    </p>
                </div>
            },
        ]
    },
    {
        id: "UppdateringLicens",
        header: "Uppdateringar och licensnyckel",
        content: <div>
            <p>
                IllumiType kollar automatiskt om det finns en ny version varje gång programmet startas. När det finns en ny version dyker det upp en dialogruta som informerar dig om att en ny version är tillgänglig.
            </p>
            <Image fluid src={uppdatering1} />
            <p>
                För att uppdatera IllumiType gör du följande
                <ol>
                    <li>
                        Öppna inställningsläget genom att trycka F12
                    </li>
                    <li>
                        I menyn till vänster hittar du rubriken <Green>
                            Uppgradera
                        </Green>
                    </li>
                    <li>
                        Till höger finns det en <Blue>
                            Uppgradera
                        </Blue> knapp som är röd om det finns en uppgradering.
                    </li>
                </ol>
            </p>
            <Image fluid src={uppdatering2} />
            <p>
                Under den här sektionen kan du också se vilket namn och organisation din nuvarande licensnyckel är registrerad till. Det finns en knapp längst upp till höger för att <Orange>
                    ändra/uppdatera din licensnyckel
                </Orange>.
            </p>
            <p>
                Här visas även de ändringar som gjorts i den senaste uppdateringen av programmet.
            </p>
        </div>
    },
    {
        id: "fjarrtolkning",
        header: "Fjärrtolkning lokalt",
        content: <div>
            <p>
                Starta den router som är kopplad till din dator och se till att datorn har anslutit till rätt nätverk. Gå in i inställningsläget och tryck på <Yellow>
                    Fjärrtolkning
                </Yellow>. Tryck på knappen <Yellow>
                    Starta fjärrserver
                </Yellow>.
            </p>
            <Image fluid src={fjarrtolkning1} />
            <p>
                Där kan du välja högsta <Yellow>
                    deltagarantal
                </Yellow>, hur många som kan vara anslutna beror på vad du har för router och vad den klarar av. Du kan även välja om <Blue>
                    skrollock
                </Blue> ska vara aktivt eller inte och om <Green>
                    klientknappar
                </Green> ska synas. Skrollock är om tolkanvändaren ska kunna skrolla i texten själv eller om det är låst att alltid senaste texten visas. Klientknappar är knappar som tolkanvändaren ser och kan bestämma storlek och färg på text och bakgrund, annars är det låst på en viss storlek och vit text med svart bakgrund.
            </p>
            <Image fluid src={fjarrtolkning2} />
            <p>
                När du har skapat en fjärrserver så ser du vad fjärrsessionens <Yellow>
                    IP-adress
                </Yellow> är. Under det kan du se <Blue>
                    vilka som är anslutna
                </Blue> till fjärrsessionen och vilken <Green>
                    tid
                </Green> de anslöt till sessionen.
            </p>
            <Image fluid src={fjarrtolkning3} />
            <p>
                När fjärrsessionen är uppe kan du ansluta en annan dator, telefon eller surfplatta för att komma åt tolkningen. Se först till att din valda enhet är kopplad tillt samma nätverk som tolkdatorn. Gå sedan in i webbläsaren och skriv in fjärrsessionens IP-adress. Det kommer upp en ruta där du skriver in ett namn, det är det namnet som kommer visas i IllumiType där du ser vilka som är anslutna.
            </p>
            <Image fluid src={fjarrtolkning4} />
            <p>
                När en enhet kopplat upp sig till fjärrsessionen får du också upp en liten notifikation i hörnet om att “X har kopplat upp sig”. Du kan på samma sätt se när en enhet har kopplat ner sig.
            </p>
            <Image fluid src={fjarrtolkning5} />
            <Image fluid src={fjarrtolkning6} />
            <p>
                Du kan nu börja skriva i textläget och det kommer dyka upp på din kopplade enhet. De kommer bara se texten och inte om du öppnar inställningsläget, förkortningsrutan eller andra funktioner i IllumiType.
            </p>
        </div>
    },
    {
        id: "Distanstolkning",
        header: "Distanstolkning",
        content: <div>
            <p>
                Distanstolkning kallas det när skrivtolkarna inte är på samma plats som tolkanvändaren. Det kan användas antingen när användarna sitter på samma plats, eller i helt digitala sammanhang där alla är uppkopplade på varsitt håll.
            </p>
        </div>,
        subHeaders: [
            {
                id: "skapadistans",
                header: "Skapa en distanssession",
                content: <div>
                    <p>
                        <ol>
                            <li>
                                Gå in i inställningsläget genom att trycka på <b>F12</b>.
                            </li>
                            <li>
                                I menyn till vänster väljer du rubriken <Yellow>
                                    Fjärrtolkning
                                </Yellow>
                            </li>
                            <Image fluid src={distanstolkning1} />
                            <li>
                                Under “Distanstolkning” klickar du på <Blue>
                                    Skapa ny
                                </Blue>.
                            </li>
                            <Image fluid src={distanstolkning2} />
                            <li>
                                En ruta visas. Ange vad sessionen ska heta. Med <Pink>
                                    reglaget
                                </Pink> kan du ställa in om sessionen ska vara “öppen för alla” eller “bara för mig”. Om du väljer “bara för mig” kan du fortfarande ge tolkanvändaren åtkomst till tolkningen - men ingen annan tolk kan ansluta sig till tolkningen.
                            </li>
                            <li>
                                Klicka på <Orange>
                                    Skapa
                                </Orange>.
                            </li>
                            <Image fluid src={distanstolkning3} />
                            <li>
                                Du blir automatiskt ansluten till den session du precis skapade.
                            </li>
                        </ol>
                    </p>
                    <p>
                        Du kan välja datum för sessionen. Servern rensar bort alla sessioner vars datum ligger i dåtid. Detta sker varje dag vid midnatt.
                    </p>
                </div>
            },
            {
                id: "anslutbefintlig",
                header: "Ansluta till en befintlig distanssession",
                content: <div>
                    <p>
                        <ol>
                            <li>
                                Gå in i inställningsläget genom att trycka på <b>F12</b>.
                            </li>
                            <li>
                                I menyn till vänster väljer du rubriken “Fjärrtolkning”.
                            </li>
                            <li>
                                Bredvid “Distanstolkning” klickar du på knappen <Green>
                                    Hämta sessioner
                                </Green>.
                            </li>
                            <Image fluid src={distanstolkning4} />
                            <li>
                                En lista över de sessioner som är öppna för alla visas.
                            </li>
                            <li>
                                Hitta den session du vill ansluta dig till och klicka på <Blue>
                                    Anslut
                                </Blue>.
                            </li>
                            <Image fluid src={distanstolkning5} />
                            <li>
                                Nu är du ansluten till sessionen. Du kan se vilka andra tolkar och tolkanvändare som är anslutna till sessionen och en ruta visas till höger i bild där det står att du är ansluten.
                            </li>
                            <Image fluid src={distanstolkning6} />
                            <li>
                                Lämna sessionen genom att klicka på <Orange>
                                    Koppla ner
                                </Orange>. Du hittar den knappen genom att trycka på F12 för att komma till inställningsläget, och sedan välja “Fjärrtolkning”.
                            </li>
                            <Image fluid src={distanstolkning7} />
                        </ol>
                    </p>
                    <p>
                        Listan över sessioner är uppdelad i tre typer: Idag, återkommande och övriga. Under övriga ligger sessioner som har ett senare datum än dagens datum.
                    </p>
                    <Image fluid src={distanstolkning13} />
                    <p>
                        Det går även att söka efter sessioner genom att använda sökrutan högst upp under Distanstolkning.
                    </p>
                    <Image fluid src={distanstolkning14} />
                </div>
            },
            {
                id: "hittaurl",
                header: "Hitta sessionens URL",
                content: <div>
                    <p>
                        Varje distanssession har en URL, för att hitta den, följ instruktionerna nedan:
                    </p>
                    <p>
                        <ol>
                            <li>
                                Gå in i inställningsläget genom att trycka på <b>F12</b>
                            </li>
                            <li>
                                I menyn till vänster väljer du rubriken “Fjärrtolkning”.
                            </li>
                            <li>
                                Se till att du har skapat och anslutit dig till den session du vill ha en URL för.
                            </li>
                            <li>
                                Klicka på knappen <Yellow>
                                    URL
                                </Yellow>
                            </li>
                            <Image fluid src={distanstolkning8} />
                            <li>
                                Välj typ av text - det finns tre alternativ: distanstolkning, livetextning och XML (vMix).
                            </li>
                            <li>
                                URL:en kopieras till dina urklipp och kan enkelt klistras in där du vill genom att använda kommandot <b>CTRL+C</b>.
                            </li>
                        </ol>
                    </p>
                </div>
            },
            {
                id: "tabortsession",
                header: "Ta bort en session",
                content: <div>
                    <p>
                        <ol>
                            <li>
                                För att ta bort en session måste du först koppla ner från sessionen. Klicka på <Orange>
                                    Koppla ner
                                </Orange>.
                            </li>
                            <Image fluid src={distanstolkning9} />
                            <li>
                                Hitta den session du vill ta bort i listan över sessioner under “Distanstolkning”
                            </li>
                            <li>
                                Klicka på knappen <Yellow>
                                    Ta bort
                                </Yellow>
                            </li>
                            <Image fluid src={distanstolkning10} />
                            <li>
                                Illumitype frågar dig om du vill ta bort sessionen. Klicka på “Ja”.
                            </li>
                            <li>
                                Sessionen tas bort. Du kan behöva klicka på <Pink>
                                    Uppdatera sessioner
                                </Pink> om den inte försvinner direkt.
                            </li>
                            <Image fluid src={distanstolkning11} />
                            <li>
                                Det går inte att ta bort en session om en kollega fortfarande är ansluten till sessionen.
                            </li>
                        </ol>
                    </p>
                </div>
            },
            {
                id: "laggtillapi",
                header: "Lägga in API-nyckel",
                content: <div>
                    <p>
                        <b>OBS!</b> Om det är två tolkar uppkopplade till samma session ska detta göras av den aktiva tolken.
                    </p>
                    <ol>
                        <li>
                            Anslut dig till den session där du vill lägga in en API-nyckel.
                        </li>
                        <li>
                            Tryck ner <b>CTRL+Z</b>.
                        </li>
                        <li>
                            En ruta visas där du klistrar in API-nyckeln.
                        </li>
                        <Image fluid src={distanstolkning12} />
                        <li>
                            Stäng rutan genom att trycka enter, eller genom att klicka på “Lägg till” och sedan på “Stäng”.
                        </li>
                        <li>
                            Nu är API-nyckeln tillagd!
                        </li>
                    </ol>
                </div>
            },
            {
                id: "tvatolkar",
                header: "Två tolkar i samma session",
                content: <div>
                    <p>
                        När två tolkar är uppkopplade till samma session är den ena tolken aktiv, och den andra passiv.
                    </p>
                    <p>
                        <u>Ta över som passiv tolk</u>
                        <ol>
                            <li>
                                När du som passiv tolk är redo att ta över - tryck <b>CTRL+enter</b>.
                            </li>
                            <li>
                                Din ram går från att vara röd till gul.
                            </li>
                            <li>
                                När din kollega också har tryckt <b>CTRL+enter</b> blir din ram blå och du kan börja skriva.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <u>Lämna över som aktiv tolk</u>
                        <ol>
                            <li>
                                När du som aktiv tolk är redo att lämna över - tryck <b>CTRL+enter</b>
                            </li>
                            <li>
                                Din ram ändras från att vara blå till gul.
                            </li>
                            <li>
                                Du är fortfarande aktiv tolk, fram tills dess att din kollega också har tryckt <b>CTRL+enter</b> och din ram går från att vara gul till att vara röd. Då är kollegan aktiv tolk och du är passiv tolk.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <ul>
                            <li>
                                Ingen ram: du är den enda tolken som är uppkopplad i sessionen
                            </li>
                            <li>
                                Blå ram: en kollega är uppkopplad, du är aktiv tolk
                            </li>
                            <li>
                                Gul ram: din kollega är redo att byta
                            </li>
                            <li>
                                Röd ram: din kollega är aktiv tolk, du är passiv tolk.
                            </li>
                        </ul>
                    </p>
                </div>
            },
            {
                id: "taover",
                header: "Ta över när kollegan inte är vid datorn",
                content: <div>
                    <p>
                        Ibland kan man behöva ta över från sin kollega för att den exempelvis lämnat datorn. Då gör man såhär:
                    </p>
                    <ol>
                        <li>Tryck på F12</li>
                        <li>Under Fjärrtolkning finns en knapp som heter “Gör mig aktiv”. Tryck på den.</li>
                        <li>Du är nu aktiv tolk</li>
                    </ol>
                    <Image fluid src={taover1} />
                </div>
            },
            {
                id: "chatta",
                header: "Chatta",
                content: <div>
                    <p>
                        Det finns en inbyggd chattfunktion i Illumitype. Den finns när du som tolk är uppkopplade till en distanssession. Du kan chatta med:
                    </p>
                    <ul>
                        <li>Andra tolkar som är anslutna till samma session</li>
                        <li>Alla tolkanvändare som är uppkopplade till sessionen via sessionens URL.</li>
                    </ul>
                    <p>
                        När en tolkanvändare öppnar chatten visas en avisering högt upp till höger på skärmen.
                    </p>
                    <Image fluid src={chatta1} />
                    <p>
                        När en tolkanvändare eller en kollega skriver ett chattmeddelande visas en avisering högst upp till höger på skärmen.
                    </p>
                    <Image fluid src={chatta2} />
                    <p>
                        Tolkarna kan se alla meddelanden som skickats genom att öppna chatten.
                    </p>
                    <p>
                        <b>CTRL+M</b> öppnar chatten med alternativet skicka till “Alla tolkar” markerat.
                        <b>CTRL+R</b> öppnar chattrutan och väljer automatiskt att svara den tolkanvändare som skrev senast.
                    </p>
                    <p>
                        Man kan byta vem man vill chatta med genom att använda upp- och nedåtpilarna när chattrutan är öppen.
                    </p>
                </div>
            },
            {
                id: "redigerapassiv",
                header: "Redigera i texten som passiv tolk",
                content: <div>
                    <p>
                        Som passiv tolk kan du redigera i din kollegas text när ni båda är uppkopplade till samma session. Det går till såhär:
                        <ol>
                            <li>Med piltangenterna navigerar du i texten och markerar hela ord.</li>
                            <li>När du har markerat ett ord du vill ändra börjar du skriva. Textbakgrunden blir röd.</li>
                            <li>När du har skrivit klart ändringen trycker du på Enter. Då bekräftas ändringen. Det ändrade ordet stryks då under. Detta syns även för tolkanvändaren.</li>
                            <li>Om du har börjat ändra ett ord men inte vill ändra det längre trycker du på Esc.</li>
                        </ol>
                    </p>
                    <p>
                        Om du vill lägga till ord i kollegans text gör du såhär:
                        <ol>
                            <li>Markerar ordet som står innan ordet du vill lägga till och tryck på Space.</li>
                            <li>Textbakgrunden blir röd, men det markerade ordet tas inte bort.</li>
                            <li>Skriv in ordet som kollegan missade.</li>
                            <li>Tryck Enter när du har redigerat färdigt, eller Esc för att avbryta.</li>
                        </ol>
                    </p>
                    <p>
                        För att ta bort ett ord gör du såhär:
                        <ol>
                            <li>Markerar ordet du vill ta bort.</li>
                            <li>Tryck på Backspace.</li>
                        </ol>
                    </p>
                    <p>
                        Obs: Det går inte att trycka Enter som passiv tolk när ett ord är markerat utan att man har påbörjat en ändring.
                    </p>
                </div>
            }
        ]
    },
    {
        id: "forinskriventext",
        header: "Förinskriven text",
        content: <div>
            <p>
                För att skapa förinskriven text gör du så här:
                <ol>
                    <li>Skriva texten du vill lägga in som förinskriven text i textläget (det går även bra att klistra in texten).</li>
                    <li>Tryck på <b>F3</b> och döp din förinskrivna text.</li>
                </ol>
            </p>
            <p>
                Såhär gör du när du har skapat en förinskriven text:
                <ol>
                    <li>Tryck på <b>F2</b>.</li>
                    <li>Välj din förinskrivna text genom att navigera upp och ner med piltangenterna. Bekräfta genom att trycka på enter.</li>
                    <li>Mata fram texten genom att trycka på <b>högerpil</b>. Detta matar fram texten med ett ord i taget.</li>
                    <li>Använd <b>nedåtpil</b> för att mata fram en hel paragraf.</li>
                    <li>Använd <b>bakåtpil</b> för att ta bort det senast utmatade ordet.</li>
                </ol>
            </p>
            <p>
                Det finns en översiktsvy för din förinskrivna text där du ser var någonstans i texten du är. Den öppnas automatiskt längst ner på skärmen när du öppnar förinskriven text. Genom att trycka på <b>SHIFT+F2</b>, döljer du vyn. Tryck samma kommando en gång till för att visa översiktsvyn igen.
            </p>
            <p>
                Såhär gör du för att hoppa framåt eller bakåt i texten:
                <ul>
                    <li>
                        Tryck <b>ALT+Framåtpil</b>.
                    </li>
                    <li>
                        Det går även bra att klicka med musen på ett ord i förhandsvisningen för att hoppa direkt fram till det ordet.
                    </li>
                    <li>
                        Med <b>CTRL+Piltangent</b> kan du flytta markören i framåt och bakåt i texten utan att mata fram text, eller ta bort text. Det är bra om du behöver redigera något.
                    </li>
                </ul>
            </p>
            <p>
                Tryck på <b>ESC</b> för att avluta. Då kan du redigera och navigera i texten med piltangenterna som vanligt igen.
            </p>
        </div>
    },
    {
        id: "Snabbknappar",
        header: "Snabbknappar",
        content: <div>
            <p>
                Det finns många kortkommandon och snabbknappar i IllumiType.
            </p>
        </div>,
        subHeaders: [
            {
                id: "textläge",
                header: "I textläge",
                content: <div>
                    <ul>
                        <li>
                            F1 - söker i de aktiva förkortningslistorna efter en förkortning på det senast skrivna ordet. Om det finns fler än en förkortning för ordet väljer programmet den kortaste av förkortningarna. Dessa visas som en liten blå ruta högst uppe till höger i bild. Om du vill söka efter en förkortning på ett ord längre upp i texten markerar du det ordet och trycker på F1.
                        </li>
                        <li>
                            F2 - Menyn Förinskriven text visas. Om en förinskriven text väljs kan du mata ut den texten genom att trycka på höger piltangent.
                        </li>
                        <li>
                            ESC - Avaktiverar förinskriven text, så att det inte matas ut text när du trycker på höger piltangent.
                        </li>
                        <li>
                            CTRL + F2 - Avaktiverar förinskriven text, så att det inte matas ut text när du trycker på höger piltangent.
                        </li>
                        <li>
                            SHIFT + F2 - Visar en översiktsvy över den förinskrivna texten. Samma kommando tar bort översiktsvyn.
                        </li>
                        <li>
                            F3 - Rutan för att skapa förinskriven text visas.
                        </li>
                        <li>
                            F4 - Rensar hela skärmen från text.
                        </li>
                        <li>
                            F6 - Gör texten mindre. Håll in knappen så går det snabbare.
                        </li>
                        <li>
                            F7 - Gör texten större. Håll in knappen så går det snabbare.
                        </li>
                        <li>
                            F8 - Växlar mellan olika förinställda färgscheman.
                        </li>
                        <li>
                            F9 - Öppnar en ruta där du kan lägga in förkortningar i den aktiva standardlistan.
                        </li>
                        <li>
                            F10 - Öppnar en ruta där du kan lägga in förkortningar i den aktiva tilläggslistan.
                        </li>
                        <li>
                            F11 - Gå in och ut ur helskärmsläge.
                        </li>
                        <li>
                            F12 - Växla till inställningsläge.
                        </li>
                        <li>
                            CTRL + A - markera all text. Är helt avaktiverat medan man är ansluten till en session.
                        </li>
                        <li>
                            CTRL + B - Fetstilar texten. Tryck samma kommando igen för att avaktivera fetstilad text.
                        </li>
                        <li>
                            CTRL + C - kopiera
                        </li>
                        <li>
                            CTRL + M öppnar chatten med alternativet skicka till “Alla tolkar” markerat.
                        </li>
                        <li>
                            CTRL + R öppnar chattrutan och väljer automatiskt att svara den TA som skrev senast.
                        </li>
                        <li>
                            CTRL + Z och CTRL + Y - alltså redo eller undo fungerar när man inte är ansluten till en session.
                        </li>
                        <li>
                            CTRL + I - kursiverar texten. Tryck samma kommando igen för att avaktivera kursiverad text.
                        </li>
                        <li>
                            CTRL + K - kursiverar texten. Tryck samma kommando igen för att avaktivera kursiverad text.
                        </li>
                        <li>
                            CTRL + B - Fetstilar texten. Tryck samma kommando igen för att avaktivera fetstilad text.
                        </li>
                        <li>
                            CTRL + F - Fetstilar texten. Tryck samma kommando igen för att avaktivera fetstilad text.
                        </li>
                        <li>
                            CTRL + U - Stryker under texten. Tryck samma kommando igen för att avaktivera understruken text.
                        </li>
                        <li>
                            CTRL + Z - Öppnar rutan där du kan klistra in ett  API-nyckel.
                        </li>
                        <li>
                            CTRL + C - kopiera
                        </li>
                        <li>
                            CTRL + V - klistra in
                        </li>
                        <li>
                            CTRL + 1-10 - Det finns olika profiler där du kan ange prioriteringsordning för dina förkortningslistor. Profill 1 (CTRL + 1) är aktiverad som standard. Om du trycker CTRL + 2 aktiveras den prioriteringsordning som du ställt in för profil 2, på samma sätt gäller för CTRL + 3, CTRL + 4 och så vidare hela vägen upp till CTRL + 10.
                        </li>
                        <li>
                            TAB - Lägger till ett mellanslag efter en kombination av bokstäver, utan att förkortningen som låg inlagd för den kombinationen löses ut. Exempel: om någon pratar om MKT och du vill skriva det, men du har lagt in att mkt blir mycket så skriver du mkt följt av TAB. Då löses inte förkortningen ut.
                        </li>
                        <li>
                            CTRL + SPACE - Löser ut en förkortning utan att lägga till ett mellanslag efter. På så vis kan man “klistra ihop” flera förkortningar på varandra. Vill du till exempel skriva svensklärare och har svensk på svk och lärare på lre kan du skriva svk, trycka CTRL+SPACE, skriva lre och trycka SPACE så det kommer utlösas som svensklärare.
                        </li>
                        <li>
                            CTRL + TAB - Fungerar som TAB, men utan mellanslag så att du kan lägga till ett skiljetecken efter.
                        </li>
                        <li>
                            Exempel: om du har lagt in att MKT blir mycket, men du är i ett sammanhang där MKT är ett namn på ett program så kan du skriva: “...genom att klicka runt i MKT CTRL+TAB.”
                        </li>
                        <li>
                            Texten blir då “genom att klicka runt i MKT.”
                        </li>
                    </ul>
                </div>
            },
            {
                id: "installningslage",
                header: "I inställningsläge",
                content: <div>
                    <ul>
                        <li>
                            F2 - Menyn förinskriven text öppnas
                        </li>
                        <li>
                            F3 - Rutan där du skapar förinskriven text visas.
                        </li>
                        <li>
                            F9 - Öppnar en ruta där du kan lägga in förkortningar i den aktiva standardlistan.
                        </li>
                        <li>
                            F10 - Öppnar en ruta där du kan lägga in förkortningar i den aktiva tillläggslistan.
                        </li>
                        <li>
                            F11 - Gå in och ut ur helskärmsläge
                        </li>
                        <li>
                            F12 - Växla till textläge.
                        </li>
                        <li>
                            CTRL + Z - Öppnar rutan där du kan klistra in ett API-token.
                        </li>
                    </ul>
                </div>
            }
        ]
    }
]

export default function Help(props) {
    return <NavContent content={content} />
}