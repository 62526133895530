import React, { useContext, useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap'
import User from '../contexts/User';

interface Status {
    name: string
    interpreters: Array<string>
}

function SessionStatus(props: Status) {
    return (
        <Card className="m-2">
            <Card.Title>{props.name}</Card.Title>
            <Card.Text>
                {props.interpreters.map(name => <Row id={name}><Col>{name}</Col></Row>)}
            </Card.Text>
        </Card>
    )
}

function ServerStatus(props: any) {
    const user = useContext(User.Context)
    const [status, setStatus] = useState<Array<Status>>([])

    useEffect(() => {
        const updateStatus = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_ACTIVITY_URL + '/status', {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${user.data.token}`
                    }
                })

                const json: Array<Status> = await response.json()
                setStatus(json)
            } catch (err) {
                console.log(err)
            }
        }

        updateStatus()

        const interval = setInterval(updateStatus, 10000)

        return () => clearInterval(interval)
    }, [user.data.token])

    return (
        <>
            <Row className="mt-4 justify-content-center">
                <Col sm="auto">
                    <h3>ServerStatus</h3>
                </Col>
            </Row>
            {status.length === 0 ? <Row className="justify-content-center"><Col sm="auto">Ingen är ansluten just nu</Col></Row> : null}
            <Row>
                {status.map(s => <Col id={s.name}><SessionStatus {...s} /></Col>)}
            </Row>
        </>
    )
}

export default ServerStatus