import React, { useState, createContext, useContext, SetStateAction } from 'react';

export default function createContextProvider<T>(initialValues: T) {
    const Context = createContext({ data: initialValues, set: (arg: SetStateAction<T>) => { }, reset: () => { } })

    const Provider = function MyProvider(props: any): JSX.Element {
        const [data, setData] = useState(initialValues)

        return (
            <Context.Provider value={{
                data: data,
                set: setData,
                reset: () => setData(initialValues)
            }}>
                {props.children}
            </Context.Provider>
        )
    }

    return { Context, Provider }
}

export class LoadingError extends Error {
    public promise?: Promise<void>

    constructor(promise?: Promise<void>) {
        super()
        this.promise = promise
    }
}

export function createCachedContextProvider<T>(initialValues: T, retrieveFunc: (param: any) => Promise<T>, useStateFunc: () => any) {
    const newInitialValues = { values: initialValues, fetching: false, pending: true, error: "" }
    const { Context, Provider } = createContextProvider(newInitialValues)

    const useCachedData: () => [T, () => void] = () => {
        const ctx = useContext(Context)
        const state = useStateFunc()

        if (ctx.data.pending) {
            if (ctx.data.fetching) {
                throw new LoadingError()
            }

            const get = async () => {
                //setTimeout needed, we're not allowed to update state in another component (MyProvider) from within
                //the render call of another component (the component calling useCachedData)
                //putting the call inside the async function was not enough for some reason
                setTimeout(() => ctx.set({ values: initialValues, fetching: true, pending: true, error: "" }))
                const response = await retrieveFunc(state)
                ctx.set({ values: response, fetching: false, pending: false, error: "" })
            }

            const promise = get()

            throw new LoadingError(promise)
        }

        //const set = (arg: SetStateAction<T & { fetching: boolean, pending: boolean, error: string }>) => data.set(arg)

        return [
            ctx.data.values, async () => {
                const res = await retrieveFunc(state)
                ctx.set({ values: res, fetching: false, pending: false, error: "" })
            }
        ]
    }

    return { Provider, useCachedData }
}