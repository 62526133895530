import React, { useContext, useMemo, useState } from 'react';
import { Button, Col, Form, ListGroup, Modal, Row, Spinner } from 'react-bootstrap'
import Transcripts, { TranscriptData } from '../../contexts/Transcripts'
import User from '../../contexts/User';
import ConfirmDialogButton from '../../components/ConfirmDialog';
import SRT from './SRT';
import Raw from './Raw';

function Text(props: { transcript: TranscriptData }) {
    return (
        <>
            <div>
                {props.transcript.text.map((line, idx) => <div key={idx} dangerouslySetInnerHTML={{ __html: line.html }} />)}
            </div>
        </>
    )
}

const displayModes = [
    { text: "Med tidskoder", component: Raw },
    { text: "Bara text", component: Text },
    { text: "SRT", component: SRT },
]

function ShowTranscript(props: { transcript: TranscriptData, onClose: () => void, update: () => void }) {
    const [display, setDisplay] = useState(displayModes[0])
    const [saving, setSaving] = useState(false)
    const [unsavedAlert, setUnsavedAlert] = useState(false)
    const user = useContext(User.Context)

    const DisplayComponent = display.component

    const saveCurrent = async () => {
        setSaving(true)
        if (!props.transcript.name.endsWith('(redigering)')) {
            props.transcript.name = props.transcript.name + " (redigering)"
        }
        const response = await fetch(`${process.env.REACT_APP_TRANSCRIPT_URL}/transcript`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${user.data.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props.transcript),
        })

        if (response.status !== 200) {
            console.log("Error saving transcript!")
        } else {
            props.update()
            props.onClose()
        }
        setSaving(false)
    }

    return (
        <>
            <Row>
                <Col>
                    <Button variant="secondary" className="mb-2" onClick={() => {
                        if (props.transcript.edited) {
                            setUnsavedAlert(true)
                        } else {
                            props.onClose()
                        }
                    }}>Gå tillbaka</Button>
                </Col>
                {displayModes.map((mode, idx) => (
                    <Col key={idx}>
                        <Button className="mb-2" onClick={() => setDisplay(mode)}>{mode.text}</Button>
                    </Col>
                ))}
                <Col>
                    <Button disabled={saving} variant="success" onClick={saveCurrent}>{saving ? <Spinner animation="border" /> : null}Spara</Button>
                </Col>
                {user.data.roles.includes('transcripts') ?
                    <Col>
                        <ConfirmDialogButton variant="danger" label="Ta bort" text={"Vill du ta bort " + props.transcript.name} onYes={async () => {
                            const response = await fetch(`${process.env.REACT_APP_TRANSCRIPT_URL}/transcript/${props.transcript._id}`, {
                                method: "DELETE",
                                credentials: 'include',
                                headers: {
                                    'Authorization': `Bearer ${user.data.token}`,
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify(props.transcript),
                            })

                            if (response.status === 200) {
                                props.update()
                                props.onClose()
                            }
                        }} />
                    </Col> : null
                }
            </Row>
            <DisplayComponent transcript={props.transcript} />
            <Modal show={unsavedAlert}>
                <Modal.Header>
                    Du har gjort ändringar som inte är sparade.
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Button variant="danger" onClick={() => {
                                props.update()
                                props.onClose()
                            }}>Kasta</Button>
                        </Col>
                        <Col>
                            <Button variant="success" onClick={saveCurrent}>Spara</Button>
                        </Col>
                        <Col>
                            <Button variant="secondary" onClick={() => setUnsavedAlert(false)}>Avbryt</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default function Transcript(props: any) {
    const [transcriptData, update] = Transcripts.useCachedData()
    const user = useContext(User.Context)
    const [selected, setSelected] = useState<TranscriptData | null>(null)
    const [fetching, setFetching] = useState(false)
    const [search, setSearch] = useState('')

    const [text, setText] = useState('')
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)

    const filteredTranscripts = useMemo(() => {
        return transcriptData.transcripts.filter(ts => ts.name.toLowerCase().includes(search.toLowerCase()) || ts.createdAt.toLocaleDateString().includes(search))
    }, [search, transcriptData.transcripts])

    if (fetching) {
        return <Row className="justify-content-center"><Spinner animation="border" /></Row>
    }

    if (selected) {
        return <ShowTranscript update={update} transcript={selected} onClose={() => setSelected(null)} />
    }

    const onSelect = async (id: string) => {
        try {
            const response = await fetch(process.env.REACT_APP_TRANSCRIPT_URL + '/transcript/' + id, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${user.data.token}`
                }
            })

            const ts = await response.json()

            ts.createdAt = new Date(Date.parse(ts.createdAt as unknown as string))
            ts.edited = false
            for (const line of ts.text) {
                line.start = new Date(Date.parse(line.start as unknown as string))
                line.stop = new Date(Date.parse(line.stop as unknown as string))
                line.errors = []
                line.spellchecked = false
            }

            setSelected(ts)
            setFetching(false)
        } catch (err) {
            setFetching(false)
        }
    }

    const saveTranscriptFromText = async () => {
        if (!text || !name) {
            return
        }

        setLoading(true)

        const now = new Date()
        const data: TranscriptData = {
            _id: "",
            name: name,
            text: text.split('\n').map(line => {
                return {
                    html: line,
                    line: line,
                    start: now,
                    stop: now,
                    errors: [],
                    spellchecked: false,
                }
            }),
            organizations: [],
            groups: [],
            interpreterIDs: [],
            createdAt: new Date(),
            edited: false,
        }

        await fetch(`${process.env.REACT_APP_TRANSCRIPT_URL}/transcript`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${user.data.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })

        update()
        setShow(false)
        setLoading(false)
        setName('')
        setText('')
    }

    return (
        <>
            <Row className="justify-content-around">
                <Col xs="auto">
                    <Form.Control type="text" placeholder="Sök" value={search} onChange={e => setSearch(e.target.value)} />
                </Col>
                <Col xs="auto">
                    <Button onClick={() => setShow(true)}>Skapa transkribering</Button>
                </Col>
            </Row>
            <ListGroup>
                {filteredTranscripts.map(ts => (
                    <ListGroup.Item action key={ts._id} onClick={() => onSelect(ts._id)} >
                        <Row>
                            <Col>
                                {ts.name}
                            </Col>
                            < Col >
                                {ts.createdAt.toLocaleDateString()}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    Skapa en transkribering från text (utan tidskoder)
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Control value={name} onChange={e => setName(e.target.value)} placeholder="Namn" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control value={text} onChange={e => setText(e.target.value)} as="textarea" rows={10} placeholder="Klistra in texten här" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button disabled={loading} onClick={saveTranscriptFromText}>Skapa från text</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}