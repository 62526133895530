import { createCachedContextProvider } from './ContextProvider'
import User from './User'
import { useContext } from 'react'

interface SessionData {
    md5: string,
    name: string,
    type: Number,
    organizations: Array<string>,
    groups: Array<string>,
    interpreterIDs: Array<string>,
    disabled: Array<string>,
    recurring: boolean,
    SaveTranscript: boolean,
    CreateBy: string,
    BelongsTo: string,
    urls: {
        Xml: string,
        Captions: string,
        Distans: string,
        Transcript: string,
        SRT: string,
    }
}

const initialValues: Array<SessionData> = []

export default createCachedContextProvider(initialValues, async (key: string) => {
    const response = await fetch(process.env.REACT_APP_SESSIONS_URL + '/session', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${key}`
        }
    })

    const json: Array<SessionData> = await response.json()

    return json
}, () => {
    const user = useContext(User.Context)
    return user.data.token
})