import { createCachedContextProvider } from './ContextProvider'
import User from './User'
import { useContext } from 'react'

interface UsersAndGroupsData {
    users: Array<{ name: string, _id: string }>,
    organizations: Array<string>,
    groups: Array<{ name: string, description: string }>,
    roles: Array<{ name: string, description: string }>,
}

const initialValues: UsersAndGroupsData = { users: [], organizations: [], groups: [], roles: [] }

export default createCachedContextProvider(initialValues, async (token: string) => {
    const response = await fetch(process.env.REACT_APP_ACCOUNT_URL + '/usersandgroups', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    const json: UsersAndGroupsData = await response.json()

    return json
}, () => {
    const user = useContext(User.Context)
    return user.data.token
})