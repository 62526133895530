import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import { FiTrash } from 'react-icons/fi';
import ConfirmDialogButton from '../components/ConfirmDialog';
import User from '../contexts/User';
import UsersAndGroups from '../contexts/UsersAndGroups';

function AddAndRemoveList(props) {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const updateValues = () => {
        if (name && description) {
            props.add({ name, description })
            setName('')
            setDescription('')
        }
    }

    const onEnter = e => {
        if (e.keyCode === 13) {
            e.preventDefault()
            e.stopPropagation()
            updateValues()
        }
    }

    return (
        <>
            <Row className="justify-content-center">
                <Col sm="auto">
                    <h5>{props.label}</h5>
                </Col>
            </Row>
            <Table>
                <thead>
                    <tr>
                        <th>Namn</th>
                        <th>Beskrivning</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {props.values.map((val, idx) =>
                        <tr key={idx}>
                            <td>{val.name}</td>
                            <td>{val.description}</td>
                            <td>
                                <ConfirmDialogButton label={<FiTrash />} text={`Ta bort ${val.name}?`} onYes={() => props.remove(val._id)} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Row className="justify-content-center">
                <Form.Group>
                    <Form.Control placeholder="Namn" type="text" value={name} onChange={e => setName(e.target.value)} onKeyDown={onEnter} />
                    <Form.Control placeholder="Beskrivning" as="textarea" rows={4} type="text" value={description} onChange={e => setDescription(e.target.value)} onKeyDown={onEnter} />
                    <Button onClick={updateValues}>Lägg till</Button>
                </Form.Group>
            </Row>
        </>
    )
}

export default function GroupsAndRoles(props) {
    const [userAndGroups, updateUserAndGroups] = UsersAndGroups.useCachedData()
    const user = useContext(User.Context)

    const add = path => {
        return async values => {
            await fetch(process.env.REACT_APP_ACCOUNT_URL + path, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${user.data.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            })
            updateUserAndGroups()
        }
    }

    const remove = path => {
        return async id => {
            await fetch(process.env.REACT_APP_ACCOUNT_URL + path + '/' + id, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${user.data.token}`,
                    'Content-Type': 'application/json'
                }
            })
            updateUserAndGroups()
        }
    }

    return (
        <>
            <Row>
                <Col md={6}>
                    <AddAndRemoveList label="Grupper" values={userAndGroups.groups} add={add('/groups')} remove={remove('/groups')} />
                </Col>
                <Col md={6}>
                    <AddAndRemoveList label="Roller" values={userAndGroups.roles} add={add('/roles')} remove={remove('/roles')} />
                </Col>
            </Row>
        </>
    )
}