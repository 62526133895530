import React, { useState, useContext, useEffect } from 'react';
import { Button, Table, Pagination, Form, Col, Row, Accordion, Modal, Spinner, ButtonGroup } from 'react-bootstrap'
import { BiUpArrow, BiDownArrow, BiPlus, BiMinus } from 'react-icons/bi'
import User from '../contexts/User';
import ListData from '../contexts/Lists'
import ConfirmDialogButton from '../components/ConfirmDialog';

function PaginationButtons(props) {
    const { currentPage, setPage, totalPages } = props

    const numberedButtonsAmount = 4

    const paginationStart = Math.max(currentPage - (numberedButtonsAmount / 2), 1)
    const paginationEnd = Math.min(paginationStart + numberedButtonsAmount, totalPages)

    const ellipsisLength = Math.ceil(totalPages / 10)

    return (
        <Pagination>
            <Pagination.First className="d-none d-md-block" onClick={() => setPage(1)} disabled={!(currentPage > 1)} />
            <Pagination.Prev className="d-none d-md-block" onClick={() => setPage(currentPage - 1)} disabled={!(currentPage > 1)} />
            {paginationStart > 1 && <Pagination.Item onClick={() => setPage(1)}>{1}</Pagination.Item>}
            {paginationStart > 2 && <Pagination.Ellipsis onClick={() => setPage(Math.max(1, currentPage - ellipsisLength))} />}

            {[...Array(paginationEnd - paginationStart + 1).keys()].map(i => {
                const pageNumber = paginationStart + i
                return <Pagination.Item onClick={() => setPage(pageNumber)} key={pageNumber} active={pageNumber === currentPage}>{pageNumber}</Pagination.Item>
            })}

            {paginationEnd < totalPages - 1 && <Pagination.Ellipsis onClick={() => setPage(Math.min(totalPages, currentPage + ellipsisLength))} />}
            {paginationEnd < totalPages && <Pagination.Item onClick={() => setPage(totalPages)}>{totalPages}</Pagination.Item>}
            <Pagination.Next className="d-none d-md-block" onClick={() => setPage(currentPage + 1)} disabled={!(currentPage < totalPages)} />
            <Pagination.Last className="d-none d-md-block" onClick={() => setPage(totalPages)} disabled={!(currentPage < totalPages)} />
        </Pagination>
    )
}

function Lists(props) {
    const [page, setPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(25)

    const [currentSelection, setCurrentSelection] = useState(props.lists.abbreviations)


    const [search, setSearch] = useState('')
    const [perfectMatch, setPerfectMatch] = useState([])

    useEffect(() => {
        const lcSearch = search.toLowerCase()
        const newPerfectMatch = []

        if (lcSearch) {
            setCurrentSelection(props.lists.abbreviations.filter(abb => {
                const word = abb.word.toLowerCase()
                if (word === lcSearch || abb.abbreviation === lcSearch) {
                    newPerfectMatch.push(abb)
                }
                return props.search(abb, lcSearch)
            }))
            setPerfectMatch(newPerfectMatch)
        } else {
            setCurrentSelection(props.lists.abbreviations)
            setPerfectMatch([])
        }
    }, [props.lists, props.search, search])

    const totalItems = currentSelection.length
    const totalPages = Math.ceil(currentSelection.length / itemsPerPage)

    const currentPage = Math.min(page, totalPages)

    const startIndex = itemsPerPage * (currentPage - 1)

    return (
        <>
            <Form.Group as={Row}>
                <Form.Label column sm="auto">Sök</Form.Label>
                <Col>
                    <Form.Control value={search} onChange={e => {
                        setSearch(e.target.value)
                    }} type="text"></Form.Control>
                </Col>
                <Form.Label column sm="auto">visar</Form.Label>
                <Col sm="auto">
                    <Form.Control value={itemsPerPage} onChange={e => setItemsPerPage(parseInt(e.target.value))} as="select">
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </Form.Control>
                </Col>
                <Form.Label column sm="auto">förkortningar av {totalItems}</Form.Label>
            </Form.Group>
            <Row className="justify-content-center">
                <PaginationButtons currentPage={currentPage} setPage={setPage} totalPages={totalPages} />
            </Row>
            <Table size="sm" striped bordered>
                <tbody>
                    {perfectMatch.map((abb, idx) => (
                        <tr key={abb.abbreviation}>
                            <td>{abb.abbreviation}</td>
                            <td>{abb.word}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Table size="sm" striped bordered>
                <thead>
                    <tr>
                        <th>
                            <ButtonGroup>
                                <Button variant="link" onClick={() => {
                                    props.setSortByAbb(props.sortByAbb === 1 ? -1 : 1)
                                    props.setSortByWord(0)
                                }}>Förkortning</Button>
                                {props.sortByAbb === 1 && <Button variant="link" disabled><BiUpArrow /></Button>}
                                {props.sortByAbb === -1 && <Button variant="link" disabled><BiDownArrow /></Button>}
                            </ButtonGroup>
                        </th>
                        <th style={{ width: '100%' }}>
                            <ButtonGroup>
                                <Button variant="link" onClick={() => {
                                    props.setSortByWord(props.sortByWord === 1 ? -1 : 1)
                                    props.setSortByAbb(0)
                                }}>Ord</Button>
                                {props.sortByWord === 1 && <Button variant="link" disabled><BiUpArrow /></Button>}
                                {props.sortByWord === -1 && <Button variant="link" disabled><BiDownArrow /></Button>}
                            </ButtonGroup>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentSelection.slice(startIndex, startIndex + itemsPerPage).map((abb, idx) => (
                        <tr key={idx}>
                            <td>{abb.abbreviation}</td>
                            <td>{abb.word}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Row className="justify-content-center">
                <PaginationButtons currentPage={currentPage} setPage={setPage} totalPages={totalPages} />
            </Row>
        </>
    )
}

const sorting = [
    {
        label: "Alfabetisk ordning", func: (el1, el2) => {
            if (el1 < el2)
                return -1
            if (el1 > el2)
                return 1
            return 0
        }
    },
    {
        label: "Ordets längd", func: (el1, el2) => {
            if (el1.length < el2.length)
                return -1
            if (el1.length > el2.length)
                return 1
            return 0
        }
    },
    {
        label: "Antal ord", func: (el1, el2) => {
            const e1c = el1.split(' ').length
            const e2c = el2.split(' ').length
            if (e1c < e2c)
                return -1
            if (e1c > e2c)
                return 1
            return 0
        }
    }
]

const filters = [
    {
        label: "Endast fler-ords förkortningar", func: abb => {
            return abb.word.includes(' ')
        }
    }
]

const searches = [
    {
        label: "Matcha på allt", func: (abb, search) => {
            return abb.word.toLowerCase().includes(search) || abb.abbreviation.includes(search)
        }
    },
    {
        label: "Matcha ändelse", func: (abb, search) => {
            return abb.word.toLowerCase().endsWith(search) || abb.abbreviation.endsWith(search)
        }
    }
]

export default function ListSelector(props) {
    const [listData, updateListData] = ListData.useCachedData()
    const [showModal, setShowModal] = useState(false)
    const [lists, setLists] = useState(listData.lists.map(() => false))
    const [sortByAbb, setSortByAbb] = useState(1)
    const [sortByWord, setSortByWord] = useState(0)
    const [sort, setSort] = useState(0)
    const [usedFilters, setUsedFilters] = useState([])
    const [search, setSearch] = useState(0)
    const user = useContext(User.Context)

    const selectedLists = {
        abbreviations: listData.abbreviations.filter(abb => {
            return lists.includes(abb.listId)
        })
    }

    for (const filter of usedFilters) {
        selectedLists.abbreviations = selectedLists.abbreviations.filter(filter.func)
    }

    if (sortByAbb) {
        selectedLists.abbreviations.sort((el1, el2) => {
            return sorting[sort].func(el1.abbreviation, el2.abbreviation) * sortByAbb
        })
    } else {
        selectedLists.abbreviations.sort((el1, el2) => {
            return sorting[sort].func(el1.word, el2.word) * sortByWord
        })
    }

    const wordsInSelectedList = selectedLists.abbreviations.length > 0

    const [expanded, setExpanded] = useState(!wordsInSelectedList)

    return (
        <>
            <Accordion onSelect={e => setExpanded(e)} defaultActiveKey={wordsInSelectedList ? null : "1"}>
                <Row>
                    <Col>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            Välj listor och filter {expanded ? <BiMinus /> : <BiPlus />}
                        </Accordion.Toggle>
                    </Col>
                    <Col>
                        <Button className="mb-2" onClick={() => setShowModal(true)}>Ladda upp ny lista</Button>
                    </Col>
                </Row>

                <Accordion.Collapse eventKey="1">
                    <>
                        <Row>
                            <Col sm={6}>
                                {listData.lists.map((list, idx) => (
                                    <Row className="mb-1" key={idx}>
                                        {user.data.roles.includes('lists') &&
                                            <Col xs="auto">
                                                <ConfirmDialogButton size="sm" label="Ta bort" text={"Vill du ta bort " + list.name} onYes={async () => {
                                                    await fetch(process.env.REACT_APP_LISTS_URL + '/list/' + list._id, {
                                                        method: 'DELETE',
                                                        credentials: 'include',
                                                        headers: {
                                                            'Authorization': `Bearer ${user.data.token}`
                                                        }
                                                    })

                                                    updateListData()
                                                }} />
                                            </Col>
                                        }
                                        <Col>
                                            <Form.Check id={list.name} checked={lists[idx]} type="checkbox" label={list.name} onChange={e => {
                                                const newListSelection = [...lists]
                                                newListSelection[idx] = e.target.checked ? list._id : ''
                                                setLists(newListSelection)
                                            }} />
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                            <Col sm={6}>
                                <span>Sortera efter</span>
                                {sorting.map((s, idx) => (
                                    <Form.Check key={idx} id={s.label} type="radio" label={s.label} checked={idx === sort} onChange={e => {
                                        if (e.target.checked)
                                            setSort(idx)
                                    }} />
                                ))}
                                <span>Filter</span>
                                {filters.map((f, idx) => (
                                    <Form.Check key={idx} id={f.label} type="checkbox" label={f.label} checked={usedFilters.find(v => v.label === f.label) !== undefined} onChange={e => {
                                        if (e.target.checked)
                                            setUsedFilters([...usedFilters, f])
                                        else {
                                            const newFilters = usedFilters.filter(v => v !== f)
                                            setUsedFilters(newFilters)
                                        }
                                    }} />
                                ))}
                            </Col>
                        </Row>
                        <Row>
                            {searches.map((s, idx) => (
                                <Col key={idx} sm="auto">
                                    <Form.Check id={s.label} type="radio" label={s.label} checked={idx === search} onChange={e => {
                                        if (e.target.checked)
                                            setSearch(idx)
                                    }} />
                                </Col>
                            ))}
                        </Row>
                    </>
                </Accordion.Collapse>
            </Accordion>
            {wordsInSelectedList && <Lists lists={selectedLists} sortByAbb={sortByAbb} setSortByAbb={setSortByAbb} sortByWord={sortByWord} setSortByWord={setSortByWord} search={searches[search].func} />}
            <UploadListModal update={updateListData} show={showModal} onHide={() => setShowModal(false)} />
        </>
    )
}

function UploadListModal(props) {
    const user = useContext(User.Context)
    const [file, setFile] = useState()
    const [sending, setSending] = useState(false)

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header>Ladda upp lista</Modal.Header>
            <Modal.Body>
                <Form.Control onChange={e => setFile(e.currentTarget.files[0])} type="file" />
                <Row className="justify-content-center mt-2">
                    <Col sm="auto">
                        <Button disabled={sending} onClick={() => {
                            try {
                                const fr = new FileReader()
                                fr.onload = async e => {
                                    try {
                                        setSending(true)
                                        JSON.parse(e.target.result)

                                        await fetch(process.env.REACT_APP_LISTS_URL + '/list', {
                                            method: 'POST',
                                            credentials: 'include',
                                            headers: {
                                                'Authorization': `Bearer ${user.data.token}`,
                                                'Content-Type': 'application/json'
                                            },
                                            body: e.target.result
                                        })

                                        setSending(false)
                                        props.onHide()
                                        props.update()
                                    } catch (err) {
                                        console.log(err)
                                    }
                                }
                                fr.readAsText(file)
                            } catch (err) {
                                console.log(err)
                            }
                        }}>{sending ? <Spinner size="sm" animation="border" /> : 'Ladda upp'}</Button>
                    </Col>
                    <Col sm="auto">
                        <Button onClick={props.onHide}>Stäng</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}