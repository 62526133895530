import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, ListGroup, Modal, Row, Form as BForm, Dropdown, DropdownButton } from 'react-bootstrap'
import Session from '../contexts/Sessions'
import { string, array, object, bool } from 'yup'
import { Formik, Form } from 'formik';
import { FormCheck, FormControl, FormArray } from '../utility/FormHelper';
import User from '../contexts/User';
import ConfirmDialogButton from '../components/ConfirmDialog';
import UsersAndGroups from '../contexts/UsersAndGroups';

const schema = object().shape({
    name: string().required("Du måste ange en namn"),
    date: string().required("Du måste ange ett datum"),
    id: string().test('is-valid-id', 'ID måste vara 24 tecken, eller lämnas tomt', val => !val || val.length === 24),
    interpreterIDs: array(),
    organizations: array(),
    groups: array(),
    disabled: array(),
    recurring: bool().required(),
    SaveTranscript: bool().required(),
    CreatedBy: string(),
    BelongsTo: string(),
})

const features = [
    "Distans",
    "Livetext",
    "Transkribering",
    "SRT Transkribering",
    "API nyckel",
]

function URLButtons(props) {
    const [message, setMessage] = useState('')

    useEffect(() => {
        if (message) {
            const timeout = setTimeout(() => setMessage(''), 3000)
            return () => clearTimeout(timeout)
        }
    })

    if (!props.urls?.Distans && !props.urls?.Captions && !props.urls?.Xml) {
        return null
    }

    const copyURL = (url) => {
        navigator.clipboard.writeText(url)
        setMessage("Länk kopierad!")
    }

    return <>
        <Row>
            <Col xs="auto">
                <DropdownButton className="mb-2" title="URL till sessionen">
                    {props.urls?.Distans ? <Dropdown.Item onClick={() => copyURL(props.urls?.Distans)}>Distanstolkning</Dropdown.Item> : null}
                    {props.urls?.Captions ? <Dropdown.Item onClick={() => copyURL(props.urls?.Captions)}>Livetextning</Dropdown.Item> : null}
                    {props.urls?.Xml ? <Dropdown.Item onClick={() => copyURL(props.urls?.Xml)}>XML (vMix)</Dropdown.Item> : null}
                </DropdownButton>
            </Col>
            <Col>
                {message}
            </Col>
        </Row>
    </>
}

function CreateModal(props) {
    const user = useContext(User.Context)

    const interpreterNames = props.values.interpreterIDs.map(i => props.usersAndGroups.users.find(u => u._id === i)?.name)

    if (props.values.date) {
        props.values.date = props.values.date.split('T')[0]
    } else {
        props.values.date = new Date().toISOString().split('T')[0]
    }

    if (!props.values.disabled) {
        props.values.disabled = []
    }

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header>{props.values.id ? 'Uppdatera session' : 'Skapa ny session'}</Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ ...props.values, interpreterIDs: interpreterNames }}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting }) => {

                        for (let i = 0; i < values.interpreterIDs.length; i++) {
                            values.interpreterIDs[i] = props.usersAndGroups.users.find(u => u.name === values.interpreterIDs[i])._id
                        }

                        if (values.id === "") {
                            delete values.id
                        }

                        values.type = 3
                        values.md5 = values.id
                        values.date = new Date(values.date).toISOString()

                        await fetch(process.env.REACT_APP_SESSIONS_URL + '/session' + (props.values.id ? `/${props.values.id}` : ''), {
                            method: props.values.id ? 'PUT' : 'POST',
                            credentials: 'include',
                            headers: {
                                'Authorization': `Bearer ${user.data.token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(values)
                        })
                        props.update()
                        props.onHide()
                        setSubmitting(false)
                    }}
                >
                    {(formik) => (
                        <Form>
                            {props.values.id ? null : <FormControl type="text" name="id" placeholder="ID eller lämna tomt" />}
                            <FormControl type="text" name="name" />
                            <FormControl type="date" name="date" placeholder="Datum" />
                            <FormArray name="organizations" values={formik.values.organizations} options={props.usersAndGroups.organizations} label="Organisationer" />
                            <FormArray name="interpreterIDs" values={formik.values.interpreterIDs} options={props.usersAndGroups.users.map(u => u.name)} label="Tolkar" />
                            <FormArray name="groups" values={formik.values.groups} options={props.usersAndGroups.groups.map(g => g.name)} label="Grupper" />
                            <FormArray name="disabled" values={formik.values.disabled} options={features} label="Avstängda funktioner" />
                            <FormCheck placeholder="Återkommande" name="recurring" />
                            <FormCheck placeholder="Spara transkribering" name="SaveTranscript" />
                            <Row>
                                <Col>
                                    <URLButtons urls={props.values.urls} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button disabled={formik.isSubmitting} type="submit">{props.values.id ? 'Uppdatera' : 'Skapa'}</Button>
                                </Col>
                                {props.values.id &&
                                    <>
                                        <Col>
                                            <ConfirmDialogButton label="Ta bort" text={`Vill du ta bort ${props.values.name}?`} onYes={async () => {
                                                await fetch(process.env.REACT_APP_SESSIONS_URL + `/session/${props.values.id}/remove`, {
                                                    method: 'DELETE',
                                                    credentials: 'include',
                                                    headers: {
                                                        'Authorization': `Bearer ${user.data.token}`,
                                                        'Content-Type': 'application/json'
                                                    },
                                                })
                                                props.update()
                                                props.onHide()
                                            }} />
                                        </Col>
                                    </>
                                }
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal >
    )
}

export default function Sessions(props) {
    const emptyDefaultValues = {
        md5: '', name: '', organizations: [], groups: [], interpreterIDs: [], recurring: false, disabled: [], SaveTranscript: false
    }
    const [showModal, setShowModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState(emptyDefaultValues)

    const [sessions, updateSessions] = Session.useCachedData()
    const [usersAndGroups] = UsersAndGroups.useCachedData()

    const [search, setSearch] = useState('')

    const filteredSessions = useMemo(() => {
        return sessions.filter(session => session.name.toLowerCase().includes(search.toLowerCase()))
    }, [sessions, search])

    return (
        <>
            <Row className="justify-content-center">
                <Col sm="auto">
                    <BForm.Control type="text" value={search} onChange={e => setSearch(e.target.value)} placeholder="Sök" />
                </Col>
            </Row>
            <ListGroup>
                {filteredSessions.map(sess => (
                    <ListGroup.Item action key={sess.id} onClick={() => {
                        setDefaultValues(sess)
                        setShowModal(true)
                    }}>
                        {sess.name}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Row className="justify-content-center">
                <Button className="mt-2" onClick={() => { setDefaultValues(emptyDefaultValues); setShowModal(true) }}>Skapa ny</Button>
            </Row>
            <CreateModal usersAndGroups={usersAndGroups} show={showModal} onHide={() => setShowModal(false)} update={updateSessions} values={defaultValues} />
        </>
    )
}