import { createCachedContextProvider } from './ContextProvider'
import User from './User'
import { useContext } from 'react'

interface AccountData {
    first_name: string;
    last_name: string;
    email: string;

    organization: string;
    groups: Array<string>;

    roles: Array<string>;

    licenseKey: string;

    _id: string;
}

const initialValues: Array<AccountData> = []

export default createCachedContextProvider(initialValues, async (token: string) => {
    const response = await fetch(process.env.REACT_APP_ACCOUNT_URL + '/all', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    const json: Array<AccountData> = await response.json()

    return json
}, () => {
    const user = useContext(User.Context)
    return user.data.token
})