import { createCachedContextProvider } from './ContextProvider'
import User from './User'
import { useContext } from 'react'

export interface Paragraph {
    line: string,
    html: string,
    start: Date,
    stop: Date,
    errors: Array<string>,
    spellchecked: boolean,
}

export interface TranscriptData {
    _id: string,
    name: string,
    text: Array<Paragraph>,
    organizations: Array<string>,
    groups: Array<string>,
    interpreterIDs: Array<string>,
    createdAt: Date,
    edited: boolean,
}

export interface TranscriptsAndWordlist {
    transcripts: Array<TranscriptData>,
    wordlist: Map<string, boolean>,
}

const initialValues: TranscriptsAndWordlist = { transcripts: [], wordlist: new Map() as Map<string, boolean> }

export default createCachedContextProvider(initialValues, async (token: string) => {
    const response = await fetch(process.env.REACT_APP_TRANSCRIPT_URL + '/transcript', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    const transcripts: Array<TranscriptData> = await response.json()
    for (const ts of transcripts) {
        ts.createdAt = new Date(Date.parse(ts.createdAt as unknown as string))
        /*ts.edited = false
        for (const line of ts.text) {
            line.start = new Date(Date.parse(line.start as unknown as string))
            line.stop = new Date(Date.parse(line.stop as unknown as string))
            line.errors = []
            line.spellchecked = false
        }*/
    }

    /*const wordlistResponse = await fetch(process.env.REACT_APP_TRANSCRIPT_URL + '/wordlist', {
        method: 'GET',
        headers: {
            'API-Key': token
        }
    })
    const wordarray = await wordlistResponse.json()*/
    const wordlist = new Map()
    /*for (const word of wordarray) {
        wordlist.set(word, true)
    }*/

    return { transcripts, wordlist }
}, () => {
    const user = useContext(User.Context)
    return user.data.token
})