import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './darkly-bootstrap.css'
import './App.css';
import { Container } from 'react-bootstrap';
import Login from './views/Login';
import AppDataProvider from './contexts/AppDataProvider';

function App() {
  return (
    <AppDataProvider>
      <Container>
        <Login />
      </Container>
    </AppDataProvider>
  );
}

export default App;
