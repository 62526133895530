import React from 'react';
import { Row, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import { LoadingError } from '../contexts/ContextProvider';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { loading: false }
    }

    static getDerivedStateFromError(error) {
        if (error instanceof LoadingError) {
            return { loading: true }
        }
        throw error
    }

    componentDidCatch(error) {
        if (error instanceof LoadingError && error.promise) {
            error.promise.then(() => this.setState({ loading: false }))
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.setState({ loading: false })
        }
    }

    render() {
        if (this.state.loading) {
            return <Row className="justify-content-center"><Spinner className="m-5" size={50} animation="border" /></Row>
        }

        return this.props.children
    }
}

export default withRouter(ErrorBoundary)