import React from 'react'
import User from './User'
import Account from './Account'
import Version from './Version'
import Lists from './Lists'
import Sessions from './Sessions'
import UsersAndGroups from './UsersAndGroups'
import Transcripts from './Transcripts'
import ActivityLog from './Activity'

function AppDataProvider(props: React.PropsWithChildren<{}>) {
    return (
        <User.Provider>
            <Account.Provider>
                <Version.Provider>
                    <Lists.Provider>
                        <Sessions.Provider>
                            <UsersAndGroups.Provider>
                                <Transcripts.Provider>
                                    <ActivityLog.Provider>
                                        {props.children}
                                    </ActivityLog.Provider>
                                </Transcripts.Provider>
                            </UsersAndGroups.Provider>
                        </Sessions.Provider>
                    </Lists.Provider>
                </Version.Provider>
            </Account.Provider>
        </User.Provider>
    )
}

export default AppDataProvider