import React, { useState, useMemo } from 'react'
import { Form, ListGroup, Row, Col } from 'react-bootstrap'
import { BsArrowRight, BsExclamationOctagon } from 'react-icons/bs'
import { Paragraph, TranscriptData } from '../../contexts/Transcripts'

function updateDate(date: Date, time: string) {
    const newDate = Date.parse(`${date.toDateString()} ${time}`)
    if (isNaN(newDate)) {
        return date
    }

    return new Date(newDate)
}

function RawBlock(props: { line: Paragraph, onEdit: () => void }) {
    return (
        <ListGroup.Item>
            <Row>
                <Col sm="auto">
                    <Row className="align-items-center">
                        <div suppressContentEditableWarning contentEditable onInput={e => {
                            props.line.start = updateDate(props.line.start, e.currentTarget.innerText)
                            props.onEdit()
                        }}>{props.line.start.toLocaleTimeString()}</div> <BsArrowRight /> <div suppressContentEditableWarning contentEditable onInput={e => {
                            props.line.stop = updateDate(props.line.stop, e.currentTarget.innerText)
                            props.onEdit()
                        }}>{props.line.stop.toLocaleTimeString()}</div>
                    </Row>
                </Col>
                <Col sm="auto">
                    {props.line.errors.length > 0 ? <Row className="ml-2"><BsExclamationOctagon color="red" />{props.line.errors.map(text => <div className="ml-2 mr-2 alert-text">{text}</div>)}<BsExclamationOctagon className="ml-2" color="red" /></Row> : null}
                </Col>
            </Row>
            < Row >
                <div onInput={(e) => {
                    props.line.html = e.currentTarget.innerHTML.replace("&nbsp;", " ")
                    props.line.line = e.currentTarget.innerText.trim()
                    props.onEdit()
                }} contentEditable dangerouslySetInnerHTML={{ __html: props.line.html }} />
            </Row>
        </ListGroup.Item>
    )
}

enum ShowBlocks {
    All,
    AllErrors,
    Overlap,
    CPS,
    Spelling,
}

export default function Raw(props: { transcript: TranscriptData }) {
    const [blocks, setBlocks] = useState(ShowBlocks.All)

    const text = useMemo(() => {
        props.transcript.text.forEach((line, idx, arr) => {
            if (line.errors.length === 0) {
                const overlap = line.start < arr[idx - 1]?.stop || line.stop > arr[idx + 1]?.start
                const cps = line.line.length > 0 ? line.line.length / ((line.stop.getTime() - line.start.getTime()) / 1000) : 8
                const spelling = line.html.includes('<font color="#ff0000">')
                if (overlap) {
                    line.errors.push("Överlappande tidskoder")
                }
                if (cps < 6) {
                    line.errors.push("För låg CPS. Minska tiden för blocket.")
                }
                if (cps > 10) {
                    line.errors.push("För hög CPS. Öka tiden för blocket.")
                }
                if (spelling) {
                    line.errors.push("Stavning")
                }
            }
        })
        return props.transcript.text
    }, [])

    const getFilteredText = () => {
        switch (blocks) {
            case ShowBlocks.All:
                return text
            case ShowBlocks.AllErrors:
                return text.filter(line => line.errors.length > 0)
            case ShowBlocks.CPS:
                return text.filter(line => line.errors.includes("För låg CPS. Minska tiden för blocket.") || line.errors.includes("För hög CPS. Öka tiden för blocket."))
            case ShowBlocks.Overlap:
                return text.filter(line => line.errors.includes("Överlappande tidskoder"))
            case ShowBlocks.Spelling:
                return text.filter(line => line.errors.includes("Stavning"))
        }
    }

    const filteredText = getFilteredText()

    return (
        <>
            <Form.Check type="radio" name="errorRadios" id="alla" label="Alla" checked={blocks === ShowBlocks.All} onChange={(e: any) => setBlocks(ShowBlocks.All)} />
            <Form.Check type="radio" name="errorRadios" id="åtgärd" label="Alla fel" checked={blocks === ShowBlocks.AllErrors} onChange={(e: any) => setBlocks(ShowBlocks.AllErrors)} />
            <Form.Check type="radio" name="errorRadios" id="överlapp" label="Överlappande tidskoder" checked={blocks === ShowBlocks.Overlap} onChange={(e: any) => setBlocks(ShowBlocks.Overlap)} />
            <Form.Check type="radio" name="errorRadios" id="cps" label="För hög/låg CPS" checked={blocks === ShowBlocks.CPS} onChange={(e: any) => setBlocks(ShowBlocks.CPS)} />
            <Form.Check type="radio" name="errorRadios" id="stavning" label="Stavning" checked={blocks === ShowBlocks.Spelling} onChange={(e: any) => setBlocks(ShowBlocks.Spelling)} />
            <ListGroup>
                {filteredText.map((line) => {
                    return <RawBlock onEdit={() => { props.transcript.edited = true }} line={line} />
                })
                }
            </ListGroup>
        </>
    )
}