import React, { useContext, useState } from 'react'
import { Row, Button, Col, Modal, ModalBody } from 'react-bootstrap'
import GoogleLogin, { GoogleLogout } from 'react-google-login'
import User from '../contexts/User'
import Navigation from '../components/Navigation'
import { string, object } from 'yup'
import { Formik, Form } from 'formik'
import { FormControl } from '../utility/FormHelper'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useParams } from 'react-router-dom'
import jwtVerify from '../../node_modules/jose/dist/browser/jwt/verify.js'
import { importJWK } from '../../node_modules/jose/dist/browser/key/import.js'
import Help from './Help'

const schema = object().shape({
    username: string().required("Du måste ange ett användarnamn"),
    password: string().required("Du måste ange ett lösenord")
})

function LoginScreen(props) {
    const user = useContext(User.Context)

    const [showResetModal, setShowResetModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState('')
    const [loginError, setLoginError] = useState('')
    const [error, setError] = useState('')

    return (
        <>
            <Row className="justify-content-center align-items-center"><h2>Distansportalen</h2><h5 className="ml-2">- Logga in</h5></Row>
            <Row className="justify-content-around">
                <Col md="auto">
                    <p>Med ditt google konto</p>
                    <GoogleLogin
                        className="mb-2"
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText="Logga in"
                        onSuccess={async googleResponse => {
                            try {
                                const response = await fetch(process.env.REACT_APP_ACCOUNT_URL + '/auth', {
                                    method: 'GET',
                                    credentials: 'include',
                                    headers: {
                                        Authorization: "Google " + googleResponse.tokenId
                                    }
                                })

                                const json = await response.json()

                                const publicKey = await importJWK(JSON.parse(process.env.REACT_APP_JWT_PUBLIC_KEY), 'ES256')

                                const { payload } = await jwtVerify(json.token, publicKey, {
                                    issuer: "illumitype:accounts",
                                    audience: "illumitype:backend"
                                })

                                user.set({ ...user.data, ...payload, token: json.token, loggedInWithGoogle: true })
                            } catch (err) { console.log(err) }
                        }}
                        onFailure={err => setLoginError(err.details)}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                        jsSrc="https://apis.google.com/js/platform.js"
                    />
                    {loginError ? <p>{loginError}</p> : null}
                    {loginError ? <GoogleLogout clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} /> : null}
                </Col>
                <Col md="auto">
                    <p>Med användarnamn och lösenord</p>
                    <Formik
                        initialValues={{ username: '', password: '' }}
                        validationSchema={schema}
                        onSubmit={async (values, { setSubmitting }) => {
                            try {
                                const authString = btoa(Buffer.from(`${values.username}:${values.password}`, 'utf8'))
                                const res = await fetch(process.env.REACT_APP_ACCOUNT_URL + '/auth', {
                                    method: 'GET',
                                    credentials: 'include',
                                    headers: {
                                        Authorization: `Basic ${authString}`
                                    }
                                })

                                const json = await res.json()
                                if (json.error) {
                                    setError(json.error)
                                    setSubmitting(false)
                                } else {
                                    const publicKey = await importJWK(JSON.parse(process.env.REACT_APP_JWT_PUBLIC_KEY), 'ES256')

                                    const { payload } = await jwtVerify(json.token, publicKey, {
                                        issuer: "illumitype:accounts",
                                        audience: "illumitype:backend"
                                    })

                                    user.set({ ...user.data, ...payload, token: json.token, loggedInWithGoogle: false })
                                }
                            } catch (err) {
                                setError("Fick ingen kontakt med servern")
                                setSubmitting(false)
                            }
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <FormControl placeholder="E-post" type="text" name="username" />
                                <FormControl placeholder="Lösenord" type="password" name="password" />
                                {error ? <p className="text-warning">{error}</p> : null}
                                <Button className="mt-2" disabled={formik.isSubmitting} type="submit">Logga in</Button>
                            </Form>
                        )}
                    </Formik>
                    <Button className="mt-2" variant="link" onClick={() => setShowResetModal(true)}>Glömt? Återställ här</Button>
                    <Modal show={showResetModal} onHide={() => {
                        setShowResetModal(false)
                        setShowConfirmModal('')
                    }}>
                        <ModalHeader>Återställ ditt lösenord</ModalHeader>
                        <ModalBody>
                            <div style={{ display: showConfirmModal ? 'none' : null }}>
                                <Formik
                                    initialValues={{ email: '' }}
                                    validationSchema={object().shape({
                                        email: string().email("Du måste ange en giltig e-post").required("Du måste enge en e-post")
                                    })}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        const res = await fetch(process.env.REACT_APP_ACCOUNT_URL + '/reset', {
                                            method: 'POST',
                                            body: JSON.stringify({ email: values.email }),
                                            headers: {
                                                'Content-Type': 'application/json'
                                            }
                                        })

                                        const json = await res.json()
                                        if (json.success) {
                                            setShowConfirmModal(`Ett mail har skickats till ${values.email} med intruktioner för att återställa ditt lösenord.`)
                                        } else {
                                            setShowConfirmModal(`Något gick fel med återställningen. Om problemet kvarstår, vänligen kontakta en systemadministratör.`)
                                        }
                                        setSubmitting(false)
                                    }}
                                >
                                    {formik => (
                                        <Form>
                                            <FormControl placeholder="E-post" type="text" name="email" />
                                            <Button disabled={formik.isSubmitting} type="submit">Återställ</Button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div style={{ display: showConfirmModal ? null : 'none' }}>
                                <div>{showConfirmModal}</div>
                                <Button onClick={() => {
                                    setShowResetModal(false)
                                    setShowConfirmModal('')
                                }}>Ok</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </>
    )
}

function SetPassword(props) {
    const { token } = useParams()
    const [message, setMessage] = useState('')

    return (
        <>
            <Row className="justify-content-center">
                <h2>{props.reset ? "Återställ lösenord" : "Aktivera konto - välj lösenord"}</h2>
            </Row>
            <Row className="justify-content-center">
                <Formik
                    initialValues={{ password: '', repeatpassword: '' }}
                    validationSchema={object().shape({
                        password: string().required("Du måste ange ett nytt lösenord"),
                        repeatpassword: string()
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                        const res = await fetch(process.env.REACT_APP_ACCOUNT_URL + (props.reset ? '/resetpassword' : '/activate'), {
                            method: 'POST',
                            body: JSON.stringify({ password: values.password, token: token }),
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })

                        const json = await res.json()
                        if (json.success) {
                            setMessage(`${props.reset ? "Ditt lösenord har återställts. " : ""}Du kan nu logga in med det nya lösenordet.`)
                        } else {
                            setMessage(`Något gick fel. Om problemet kvarstår, vänligen kontakta en systemadministratör.`)
                        }
                        setSubmitting(false)
                    }}
                >
                    {formik => (
                        message ?
                            <>
                                <Col>
                                    <Row className="justify-content-center">{message}</Row>
                                    <Row className="justify-content-center">
                                        <Button as={Link} to="/">Till inloggning</Button>
                                    </Row>
                                </Col>
                            </>
                            :
                            <Form>
                                <FormControl placeholder="Nytt lösenord" type="password" name="password" />
                                <FormControl placeholder="Upprepa lösenordet" type="password" name="repeatpassword" />
                                <Button disabled={formik.isSubmitting} type="submit">{props.reset ? "Återställ" : "Välj lösenord"}</Button>
                            </Form>
                    )}
                </Formik>
            </Row>
        </>
    )
}

function Login(props) {
    const user = useContext(User.Context)
    return (
        <>
            <Router basename={process.env.NODE_ENV === 'production' ? "/nya_distansportalen" : null}>
                <Switch>
                    <Route path="/reset/:token">
                        <SetPassword reset />
                    </Route>
                    <Route path="/activate/:token">
                        <SetPassword />
                    </Route>
                    <Route path="/help">
                        <Help />
                    </Route>
                    <Route path="/">
                        {user.data.email ? <Navigation /> : <LoginScreen />}
                    </Route>
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </Router>
        </>
    )
}

export default Login