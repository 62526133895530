import { createCachedContextProvider } from './ContextProvider'
import User from './User'
import { useContext } from 'react'
import localforage from 'localforage'

interface ListData {
    _id: string,
    name: string,
    type: Number,
    organization: string,
}

interface AbbData {
    listId: string,
    abbreviation: string,
    word: string,
}

export interface Lists {
    lists: Array<ListData>,
    abbreviations: Array<AbbData>,
}

const initialValues: Lists = { lists: [], abbreviations: [] }

async function getList(list: ListData, token: string) {
    const response = await fetch(process.env.REACT_APP_LISTS_URL + '/abbreviations', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ list })
    })

    const json = await response.json()
    return json.abbreviations
}

export default createCachedContextProvider(initialValues, async (token: string) => {
    const response = await fetch(process.env.REACT_APP_LISTS_URL + '/lists', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    const json: { lists: Array<ListData> } = await response.json()

    const allLists: Lists = { lists: json.lists, abbreviations: [] }

    localforage.iterate((val, key) => {
        if (!json.lists.find(l => l._id === key)) {
            localforage.removeItem(key)
            console.log("Removed cache", key)
        }
    })

    for (const list of json.lists) {
        const abbs: Array<AbbData> | null = await localforage.getItem(list._id)

        if (abbs) {
            allLists.abbreviations = allLists.abbreviations.concat(abbs)
        } else {
            const newAbbs = await getList(list, token)
            allLists.abbreviations = allLists.abbreviations.concat(newAbbs)
            try {
                localforage.setItem(list._id, newAbbs)
            } catch (err) {
                console.log("Failed to cache list", list._id)
            }
        }
    }

    return allLists
}, () => {
    const user = useContext(User.Context)
    return user.data.token
})