import React, { createContext, SetStateAction, useEffect, useState } from 'react'
// @ts-ignore
import jwtVerify from '../../node_modules/jose/dist/browser/jwt/verify.js'
// @ts-ignore
import { importJWK } from '../../node_modules/jose/dist/browser/key/import.js'

interface UserValues {
    first_name: string
    last_name: string
    email: string
    organization: string
    roles: Array<string>
    token: string
    licenseKey: string
    loggedInWithGoogle: boolean
}

const UserInitialValues: UserValues = {
    first_name: "",
    last_name: "",
    email: "",

    organization: "",

    roles: [],

    token: "",

    licenseKey: "",

    loggedInWithGoogle: false
}

async function getToken(authString: string) {
    try {
        const response = await fetch(process.env.REACT_APP_ACCOUNT_URL + '/renewauth', {
            method: 'GET',
            credentials: 'include',
            headers: {
                Authorization: authString
            }
        })

        const json = await response.json()

        const publicKey = await importJWK(JSON.parse(process.env.REACT_APP_JWT_PUBLIC_KEY as string), 'ES256')

        const { payload } = await jwtVerify(json.token, publicKey, {
            issuer: "illumitype:accounts",
            audience: "illumitype:backend"
        })

        return { ...payload, token: json.token }
    } catch (err) {
        console.log("Failed to renew token")
        return null
    }
}

const Context = createContext({ data: UserInitialValues, set: (arg: SetStateAction<UserValues>) => { }, reset: () => { } })

const Provider = function MyProvider(props: any): JSX.Element {
    const [data, setData] = useState(UserInitialValues)

    useEffect(() => {
        if (data.token) {
            const interval = setInterval(async () => {
                const payload = await getToken(`Bearer ${data.token}`)

                if (payload) {
                    console.log("Updated JWT token!")
                    setData(d => { return { ...d, ...payload } })
                }
            }, 1000 * 60 * 60)

            return () => clearInterval(interval)
        }
    }, [data.token])

    return (
        <Context.Provider value={{
            data: data,
            set: setData,
            reset: () => setData(UserInitialValues)
        }}>
            {props.children}
        </Context.Provider>
    )
}

const User = { Context, Provider }

export default User