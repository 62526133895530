import { createCachedContextProvider } from './ContextProvider'
import User from './User'
import { useContext } from 'react'

interface VersionData {
    version: string,

    onlyUpdate: boolean,
    changelog: Array<string>,
    stable: boolean,
}

const initialValues: Array<VersionData> = []

export default createCachedContextProvider(initialValues, async (token: string) => {
    const response = await fetch(process.env.REACT_APP_UPDATE_URL + '/all', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    const json: Array<VersionData> = await response.json()

    return json
}, () => {
    const user = useContext(User.Context)
    return user.data.token
})