import React, { useContext, useState } from 'react';
import { FieldArray, FormikContext, useField } from 'formik'
import { Form, Col, InputGroup, Image, Row, Button, Dropdown } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import { FiPlus } from 'react-icons/fi'

import "react-datepicker/dist/react-datepicker.css"

registerLocale('sv', sv)

function toLabel(str) {
    return str[0].toUpperCase() + str.toLowerCase().trim().slice(1).replace(/_(.)/g, ' $1')
}

export function FormControl(props) {
    const name = props.name
    const [field, meta] = useField(name)
    const error = meta.touched && typeof meta.error === "string" && meta.error

    const placeholder = props.noPlaceholder ? undefined : (
        props.placeholder ? props.placeholder : toLabel(name)
    )

    return (
        <Form.Group as={props.Col ? Col : undefined} xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
            {props.label && <Form.Label htmlFor={name}>{toLabel(name)}</Form.Label>}
            {props.prepend && <InputGroup.Prepend><InputGroup.Text>{props.prepend}</InputGroup.Text></InputGroup.Prepend>}
            <Form.Control as={props.as} rows={props.rows} placeholder={placeholder} type={props.type} isInvalid={error} {...field}>
                {props.children}
            </Form.Control>
            {props.append && <InputGroup.Append><InputGroup.Text>{props.append}</InputGroup.Text></InputGroup.Append>}
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
    )
}

export function FormDate(props) {
    const name = props.name
    const [field, meta, helpers] = useField(name)
    const error = meta.touched && meta.error

    const [startDate, setStartDate] = useState(new Date())

    const options = { dateStyle: 'short', timeStyle: 'short' }

    return (
        <Form.Group as={props.Col ? Col : undefined} xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
            <Form.Control
                locale="sv"
                placeholderText={props.placeholder}
                showTimeInput
                timeInputLabel="Klockan"
                selected={startDate}
                withPortal
                as={DatePicker}
                {...field}
                onChange={(event) => {
                    setStartDate(event)
                    helpers.setValue(event.toString(undefined, options))
                }}
                popperModifiers={{
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: "viewport"
                    }
                }} />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group >
    )
}

export function FormFile(props) {
    const [field, meta, helpers] = useField(props.name)
    const error = meta.touched && meta.error

    delete field.value

    if (props.preview && props.preview.preview) {
        return (
            <Col>
                <Image fluid className="mt-2 mb-2" src={props.preview.preview} />
            </Col>
        )
    }

    return (
        <Form.Group as={props.Col ? Col : undefined} xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            <Form.Control {...field} accept={props.accept} type="file" onChange={(event) => {
                helpers.setValue(event.currentTarget.files[0])
            }} isInvalid={error} />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
    )
}

export function FormCheck(props) {
    const label = props.placeholder ? props.placeholder : toLabel(props.name)
    const [field, meta] = useField(props.name)
    const error = meta.touched && typeof meta.error === "string" && meta.error
    return (
        <Form.Group as={props.Col && Col} xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
            <Form.Check label={label} id={props.name} isInvalid={error} checked={field.value} {...field} />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
    )
}

export function FormSelect(props) {
    const formik = useContext(FormikContext)
    const name = props.name
    const error = formik.touched[name] && formik.errors[name]
    const options = props.children ? props.children.map((child, i) => {
        return (
            <option key={i} label={child} value={i} />
        )
    }) : null
    return (
        <Form.Group as={props.Col && Col} xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
            {props.label && <Form.Label>{toLabel(name)}</Form.Label>}
            <Form.Control disabled={props.disabled} as="select" id={name} value={formik.values[name]} isInvalid={error} onChange={formik.handleChange}>
                {options}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{formik.errors[name]}</Form.Control.Feedback>
        </Form.Group>
    )
}

export function FormText(props) {
    return (
        <Form.Group as={props.Col && Col} xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
            <Form.Control plaintext readOnly defaultValue={props.text} disabled />
        </Form.Group>
    )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Row className="mb-2">
        <Col>
            {children}
            <Button variant="link" ref={ref} onClick={e => {
                e.preventDefault()
                onClick(e)
            }}>
                <FiPlus />
            </Button>
        </Col>
    </Row>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Sök"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().includes(value),
                    )}
                </ul>
            </div>
        );
    },
);

export function FormArray(props) {
    return (
        <FieldArray name={props.name} render={arrayHelpers => {
            return (
                <>
                    <Dropdown>
                        {props.label}
                        <Dropdown.Toggle as={CustomToggle}>
                            {props.values.map((val, idx) => (
                                <Button key={idx} variant="link" onClick={() => arrayHelpers.remove(idx)}>{val}</Button>
                            ))}
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenu}>
                            {props.options.filter(org => !props.values.includes(org)).map(org => <Dropdown.Item key={org} onSelect={() => arrayHelpers.push(org)}>{org}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            )
        }} />
    )
}