import { createCachedContextProvider } from './ContextProvider'
import User from './User'
import { useContext } from 'react'

interface Settings {
    name: string,
    recurring: boolean,
    interpreterIDs: Array<string>,
    organizations: Array<string>,
    groups: Array<string>,
    disabled: Array<string>,
    date: Date,
    SaveTranscript: boolean,
    createdAt: Date,
}

export interface Activity {
    action: string,
    data: string,
    createdAt: Date,
}

export interface ActivityLog {
    _id: string,
    CreatedBy: string,
    BelongsTo: string,
    settings: Array<Settings>,
    activity: Array<Activity>,
}

const initialValues: Array<ActivityLog> = []

export default createCachedContextProvider(initialValues, async (token: string) => {
    const response = await fetch(process.env.REACT_APP_ACTIVITY_URL + '/activity', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    const json: Array<ActivityLog> = await response.json()
    for (const session of json) {
        for (const settings of session.settings) {
            settings.date = new Date(Date.parse(settings.date as unknown as string))
            settings.createdAt = new Date(Date.parse(settings.createdAt as unknown as string))
        }
        for (const activity of session.activity) {
            activity.createdAt = new Date(Date.parse(activity.createdAt as unknown as string))
        }
    }

    return json
}, () => {
    const user = useContext(User.Context)
    return user.data.token
})