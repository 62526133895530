import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Spinner, OverlayTrigger, Tooltip, Form, Button, Accordion } from 'react-bootstrap'
import { TranscriptData } from '../../contexts/Transcripts'
import User from '../../contexts/User'

interface SrtBlock {
    lines: Array<string>
    start: string
    stop: string
}

interface Weights {
    phraseStarters: Array<string>
    phraseEnders: Array<string>

    highCPS: number
    highestCPS: number
    lowCPS: number
    lowestCPS: number

    highestDisplayTimeAllowed: number
    lowestDisplayTimeAllowed: number

    goodCPSWeight: number
    okCPSWeight: number
    displayTimeWeight: number
    goodPhraseStartWeight: number
    goodPhraseEndWeight: number
    lengthWeight: number
    lengthDiffWeight: number
    longerLastLineWeight: number
    numberOfBlocksWeight: number
    badSplitWeight: number
}

const defaultWeights: Weights = {
    phraseStarters: ['som', 'att', 'för att', 'och', 'men', 'eller'],
    phraseEnders: [',', ':'],
    highCPS: 12,
    highestCPS: 15,
    lowCPS: 9,
    lowestCPS: 6,
    highestDisplayTimeAllowed: 8000,
    lowestDisplayTimeAllowed: 2000,
    goodCPSWeight: 15,
    okCPSWeight: 10,
    displayTimeWeight: 15,
    goodPhraseStartWeight: 5,
    goodPhraseEndWeight: 5,
    lengthWeight: 0.5,
    lengthDiffWeight: 0.25,
    longerLastLineWeight: 4,
    numberOfBlocksWeight: 15,
    badSplitWeight: 20,
}

function WeightInputNumber(props: { weights: Weights, set: (val: Weights) => void, member: keyof Weights, label: string }) {
    return (
        <Form.Group as={Row}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control defaultValue={props.weights[props.member]} type="text" onChange={async e => {
                const val = parseFloat(e.target.value)
                if (!isNaN(val)) {
                    props.weights[props.member] = val as never
                    props.set({ ...props.weights })
                }
            }} />
        </Form.Group>
    )
}

const weightInputData: Array<{ member: keyof Weights, label: string }> = [
    { member: "goodPhraseStartWeight", label: "Rad som börjar med [att, som, för att, och, men eller]" },
    { member: "goodPhraseEndWeight", label: "Rad som slutar med [. ! ? , :]" },
    { member: "lengthWeight", label: "Radens längd" },
    { member: "longerLastLineWeight", label: "Rad 2 längre än rad 1 i ett block" },
    { member: "numberOfBlocksWeight", label: "Antal SRT block (bättre med få block)" },
    { member: "goodCPSWeight", label: "CPS mellan 9-12" },
    { member: "okCPSWeight", label: "CPS mellan 6-15" },
    { member: "lengthDiffWeight", label: "Skillnad i längd mellan rad 1 och 2 (bättre med liten skillnad)" },
    { member: "displayTimeWeight", label: "Liggtid mellan 2-8 s" },
    { member: "badSplitWeight", label: "Dela på ord som börjar med stor bokstav" },
]

function SRTWeights(props: { weights: Weights, set: (val: Weights) => void }) {
    return (
        <Accordion>
            <Accordion.Toggle as={Button} variant="link" eventKey="srtweights">
                Vikter
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="srtweights">
                <>
                    {weightInputData.map(data => <WeightInputNumber {...props} {...data} />)}
                </>
            </Accordion.Collapse>
        </Accordion>
    )
}

export default function SRT(props: { transcript: TranscriptData }) {
    const [srt, setSrt] = useState<{ srt: Array<SrtBlock>, href: string, loading: boolean, fetching: boolean }>({ srt: [], href: '', loading: true, fetching: false })
    const [error, setError] = useState('')
    const user = useContext(User.Context)
    const [charsPerLine, setCharsPerLine] = useState("40")
    const [timeAdjust, setTimeAdjust] = useState('0s')
    const [timeFactor, setTimeFactor] = useState('1.0')
    const [weights, setWeights] = useState(defaultWeights)

    useEffect(() => {
        if (!srt.fetching) {
            const get = async () => {
                try {
                    const cpl = parseInt(charsPerLine)
                    const factor = parseFloat(timeFactor.replace(',', '.'))
                    const body = { ...props.transcript, charsPerLine: isNaN(cpl) ? 40 : Math.min(cpl, 100), timeAdjust: timeAdjust, timeFactor: factor, weights }
                    const response = await fetch(`${process.env.REACT_APP_TRANSCRIPT_URL}/srt`, {
                        method: "POST",
                        credentials: 'include',
                        headers: {
                            'Authorization': `Bearer ${user.data.token}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(body),
                    })

                    const json = await response.json() as Array<SrtBlock>

                    let text = ''
                    for (const block of json) {
                        text += `${block.start} --> ${block.stop}\r\n`
                        for (const line of block.lines) {
                            text += `${line}\r\n`
                        }
                        text += "\r\n"
                    }

                    const blob = new Blob([text], { type: "text/plain" })
                    const url = URL.createObjectURL(blob)

                    setSrt({ srt: json, href: url, loading: false, fetching: true })
                } catch (err) {
                    // @ts-ignore
                    setError(err.message)
                }
            }

            setSrt({ srt: [], href: '', loading: true, fetching: true })
            get()
        }
    }, [srt.fetching, charsPerLine, props.transcript, timeAdjust, timeFactor, user.data.token, weights])

    return (
        <>
            {error !== '' ? <Row>{error}</Row> : null}
            {srt.loading ? <Row className="justify-content-center"><Spinner animation="border" /></Row> : <>
                <Row className="align-items-center m-2">
                    <Col>
                        <a href={srt.href} download={props.transcript.name + '.srt'}>Ladda hem</a>
                    </Col>
                    <Col>
                        Tecken per rad: <Form.Control type="text" value={charsPerLine} onChange={e => setCharsPerLine(e.target.value)} />
                    </Col>
                    <Col>
                        Tidsjustering:
                        <OverlayTrigger placement="bottom" delay={{ show: 100, hide: 500 }} overlay={<Tooltip id="timeAdjust tooltip">
                            Format -1h2m3s4ms<br />Exempel:<br />1m2s = en minut 2 sekunder<br />-4h378ms = minus 4 timmar 378 millisekunder
                        </Tooltip>}>
                            <Form.Control type="text" value={timeAdjust} onChange={e => setTimeAdjust(e.target.value)} />
                        </OverlayTrigger>
                    </Col>
                    <Col>
                        Tidsfaktor:
                        <OverlayTrigger placement="bottom" delay={{ show: 100, hide: 500 }} overlay={<Tooltip id="timeFactor tooltip">
                            Justera alla tidskoder med en faktor, kan användas om du textat till en film som spelats upp i långsammare uppspelningshastighet.
                        </Tooltip>}>
                            <Form.Control type="text" value={timeFactor} onChange={e => setTimeFactor(e.target.value)} />
                        </OverlayTrigger>
                    </Col>
                    <Col>
                        <Button onClick={() => setSrt({ srt: [], href: '', loading: true, fetching: false })}>Uppdatera</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SRTWeights weights={weights} set={setWeights} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {srt.srt.map((block, idx, arr) => (
                            <Row key={idx}>
                                <Col>
                                    <div>{idx + 1}</div>
                                    <div>{`${block.start} --> ${block.stop}`}</div>
                                    {block.lines.map((line, idx) => (
                                        <div key={idx}>{line}</div>
                                    ))}
                                    <br />
                                </Col>
                            </Row>
                        ))}
                    </Col>
                </Row>
            </>}
        </>
    )
}
