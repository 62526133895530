import React, { useState, useContext } from 'react';
import { Button, ListGroup, Row, Col, Accordion, Modal } from 'react-bootstrap'
import Version from '../contexts/Version'
import { Formik, Form } from 'formik';
import { FormControl, FormCheck, FormFile } from '../utility/FormHelper';
import { string, object, bool, mixed } from 'yup'
import User from '../contexts/User';

const schema = object().shape({
    version: string().required("Du måste ange en version"),
    update: mixed(),
    installer: mixed(),
    changelog: string(),
    stable: bool().required(),
})

function CreateModal(props) {
    const user = useContext(User.Context)

    const changelog = props.values.changelog.join('\n\n')

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header>Ladda upp en ny version</Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ ...props.values, changelog }}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting }) => {
                        if (values.update || props.values.version) {
                            if (!props.values.version) {
                                const data = new FormData()
                                data.append('installer', values.installer)
                                data.append('nupkg', values.update)
                                data.append('stable', values.stable)
                                data.append('changelog', values.changelog)

                                await fetch(process.env.REACT_APP_UPDATE_URL + '/update/' + values.version, {
                                    method: 'POST',
                                    credentials: 'include',
                                    headers: {
                                        'Authorization': `Bearer ${user.data.token}`,
                                    },
                                    body: data
                                })
                            } else {
                                await fetch(process.env.REACT_APP_UPDATE_URL + '/update/' + values.version, {
                                    method: 'PUT',
                                    credentials: 'include',
                                    headers: {
                                        'Authorization': `Bearer ${user.data.token}`,
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({ stable: values.stable, changelog: values.changelog })
                                })
                            }

                            props.onHide()
                            props.update()
                            setSubmitting(false)
                        } else {
                            setSubmitting(false)
                        }
                    }}
                >
                    {(formik) => (
                        <Form>
                            <FormControl type="text" name="version" />
                            {!props.values.version ? <FormFile label="nupkg" name="update" /> : null}
                            {!props.values.version ? <FormFile label="installer" name="installer" /> : null}
                            <FormControl as="textarea" rows={10} type="text" name="changelog" />
                            <FormCheck name="stable" />
                            <Button disabled={formik.isSubmitting} type="submit">Ladda upp</Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default function Versions(props) {
    const [versions, updateVersions] = Version.useCachedData()
    const [showModal, setShowModal] = useState(false)
    const user = useContext(User.Context)

    const defaultValues = {
        version: '',
        installer: '',
        update: '',
        changelog: [],
        stable: false,
    }

    const [modalValues, setModalValues] = useState(defaultValues)

    return (
        <>
            <ListGroup>
                {versions.map(version => (
                    <ListGroup.Item key={version.version}>
                        <Row>
                            <Col sm={4}>{version.version}</Col>
                            <Col sm={4}>Installer: {version.onlyUpdate ? 'Nej' : 'Ja'}</Col>
                            <Col sm={4}>Stable: {version.stable ? 'Ja' : 'Nej'}</Col>
                        </Row>
                        <Row>
                            <Col>
                                <Accordion>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">Changelog</Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <div>
                                            {version.changelog.map((text, idx) => <p key={idx}>{text}</p>)}
                                        </div>
                                    </Accordion.Collapse>
                                </Accordion>
                            </Col>
                            <Col md="auto">
                                <Button className="mr-2" onClick={() => {
                                    setModalValues(version)
                                    setShowModal(true)
                                }}>Ändra</Button>
                                <Button onClick={async () => {
                                    await fetch(process.env.REACT_APP_UPDATE_URL + '/update/' + version.version, {
                                        method: 'DELETE',
                                        credentials: 'include',
                                        headers: {
                                            'Authorization': `Bearer ${user.data.token}`
                                        }
                                    })

                                    updateVersions()
                                }}>Ta bort</Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Row className="justify-content-center">
                <Button className="mt-2" onClick={() => { setModalValues(defaultValues); setShowModal(true) }}>Ladda upp ny</Button>
            </Row>
            <CreateModal update={updateVersions} show={showModal} onHide={() => setShowModal(false)} values={modalValues} />
        </>
    )
}