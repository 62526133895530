import React, { useContext } from 'react';
import { Accordion, AccordionContext, Button, Col, Row } from 'react-bootstrap'
import { FiMinus, FiPlus } from 'react-icons/fi'

export function Item(props) {
    return (
        <Row>
            <Col>
                <Button variant="link" href={props.href}>{props.children}</Button>
            </Col>
        </Row>
    )
}

export function Header(props) {
    const currentEventKey = useContext(AccordionContext)

    return (
        <>
            <Row>
                <Col>
                    <Accordion.Toggle as={Button} variant="link" eventKey={props.eventKey} href={props.href}>
                        {props.text} {props.expandIcon ? props.eventKey === currentEventKey ? <FiMinus /> : <FiPlus /> : null}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={props.eventKey}>
                        <div className="pl-3">
                            {props.children}
                        </div>
                    </Accordion.Collapse>
                </Col>
            </Row>
        </>
    )
}

export function ContentNavigation(props) {
    return (
        <Accordion>
            {props.children}
        </Accordion>
    )
}

export function ContentBody(props) {
    return (
        <>
            {props.content.map((item, idx) => (
                <>
                    <div className={`h${props.header} text-center ${idx === 0 ? null : 'mt-5'} mb-3`}><a href={`#${item.id}`} id={item.id}>{item.header}</a></div>
                    {item.content ? item.content : null}
                    {item.subHeaders && <ContentBody header={props.header + 2} content={item.subHeaders} />}
                </>
            ))}
        </>
    )
}

export function NavContent(props) {
    return (
        <>
            <Row>
                <Col md="auto">
                    <div className="sticky-top">
                        {props.content.map(item => (
                            <ContentNavigation>
                                <Header text={item.header} eventKey={item.id} href={`#${item.id}`} expandIcon={item.subHeaders ? true : false}>
                                    {item.subHeaders && item.subHeaders.map(sub => (
                                        sub.subHeaders ? <NavContent content={sub.subHeaders} /> : <Item href={`#${sub.id}`}>{sub.header}</Item>
                                    ))}
                                </Header>
                            </ContentNavigation>
                        ))}
                    </div>
                </Col>
                <Col>
                    <ContentBody header={3} content={props.content} />
                </Col>
            </Row>
        </>
    )
}