import React, { useState, useContext, useEffect } from 'react';
import { Button, Form as BForm, ListGroup, Modal, Col, Row } from 'react-bootstrap'
import accountData from '../contexts/Account'
import { Formik, Form } from 'formik';
import { FormControl, FormArray } from '../utility/FormHelper';
import { object, string, array } from 'yup'
import User from '../contexts/User';
import UsersAndGroups from '../contexts/UsersAndGroups';
import ConfirmDialogButton from '../components/ConfirmDialog';

const schema = object().shape({
    email: string().required("Du måste ange en e-post"),
    first_name: string().required("Du måste ange en förnamn"),
    last_name: string().required("Du måste ange en efternamn"),
    organization: string().required("Du måste ange en organisation"),
    licenseKey: string().required("Du måste ange en licensnyckel"),
    roles: array(),
    groups: array(),
})

function CreateModal(props) {
    const user = useContext(User.Context)

    const roles = props.roles.map(r => r.name)
    const groups = props.groups.map(g => g.name)

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header>{props.values._id ? 'Uppdatera konto' : 'Skapa nytt konto'}</Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={props.values}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting }) => {
                        await fetch(process.env.REACT_APP_ACCOUNT_URL + '/account' + (props.values._id ? `/${props.values._id}` : ''), {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Authorization': `Bearer ${user.data.token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(values)
                        })
                        props.update()
                        props.onHide()
                        setSubmitting(false)
                    }}
                >
                    {(formik) => (
                        <Form>
                            <FormControl type="email" name="email" />
                            <FormControl type="text" name="first_name" />
                            <FormControl type="text" name="last_name" />
                            <FormControl type="text" name="organization" />
                            <FormControl type="text" name="licenseKey" />
                            <FormArray label="Roller" name="roles" values={formik.values.roles} options={roles} />
                            <FormArray label="Grupper" name="groups" values={formik.values.groups} options={groups} />
                            <Row>
                                <Col>
                                    <Button disabled={formik.isSubmitting} type="submit">{props.values._id ? 'Uppdatera' : 'Skapa'}</Button>
                                </Col>
                                <Col>
                                    {props.values._id &&
                                        <ConfirmDialogButton label="Ta bort" text={`Vill du ta bort ${props.values.first_name} ${props.values.last_name}?`} onYes={async () => {
                                            await fetch(process.env.REACT_APP_ACCOUNT_URL + '/account/' + props.values._id, {
                                                method: 'DELETE',
                                                credentials: 'include',
                                                headers: {
                                                    'Authorization': `Bearer ${user.data.token}`
                                                }
                                            })
                                            props.update()
                                            props.onHide()
                                        }} />
                                    }
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

function generateRandomChars(length) {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

function generateLicenseKey() {
    let key = generateRandomChars(5)
    for (let i = 0; i < 5; i++) {
        key += '-' + generateRandomChars(5)
    }
    return key
}

export default function Licenses(props) {
    const [accountsData, updateAccountsData] = accountData.useCachedData()
    const [userAndGroups] = UsersAndGroups.useCachedData()
    const initVals = { email: "", first_name: "", last_name: "", organization: "", licenseKey: "", _id: "", roles: [], groups: [] }
    const [showModal, setShowModal] = useState(false)
    const [initialValues, setInitialValues] = useState(initVals)
    const [search, setSearch] = useState('')
    const [accounts, setAccounts] = useState(accountsData)

    useEffect(() => {
        setAccounts(accountsData.filter(acc => {
            const lcSearch = search.toLowerCase()
            return (
                `${acc.first_name} ${acc.last_name}`.toLowerCase().includes(lcSearch) ||
                acc.organization.toLowerCase().includes(lcSearch) ||
                acc.groups.map(g => g.toLowerCase()).find(g => g.includes(lcSearch)) ||
                acc.licenseKey.toLowerCase().includes(lcSearch)
            )
        }))
    }, [accountsData, search])

    return (
        <>
            <Row className="justify-content-center">
                <Col sm="auto">
                    <BForm.Control value={search} placeholder="Sök" type="text" onChange={e => setSearch(e.target.value)} />
                </Col>
            </Row>
            <ListGroup>
                {accounts.map(val => (
                    <ListGroup.Item action key={val.email} onClick={() => {
                        setInitialValues(val)
                        setShowModal(true)
                    }}>
                        <Row>
                            <Col>{val.first_name} {val.last_name}</Col>
                            <Col>{val.organization}</Col>
                            <Col className="d-none d-md-block">{val.licenseKey}</Col>
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup>

            <Row className="justify-content-center">
                <Button className="mt-2" onClick={() => {
                    setShowModal(true)
                    setInitialValues({ ...initVals, licenseKey: generateLicenseKey() })
                }}>Lägg till ny</Button>
            </Row>

            <CreateModal roles={userAndGroups.roles} groups={userAndGroups.groups} update={updateAccountsData} show={showModal} values={initialValues} onHide={() => setShowModal(false)} />
        </>
    )
}